import React, { useCallback, useEffect, useState } from "react";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./RadioButtons.module.scss";

export default function RadioButtons(props: ComponentProps) {
    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }

    const [selectedOption, setSelectedOption] = useState(props.value);
    const [customValue, setCustomValue] = useState("");
    const onChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            props.onChange(props.model, e.currentTarget.value);
            if (e.currentTarget.tagName.toUpperCase() === "TEXTAREA") {
                setCustomValue(e.currentTarget.value);
            } else {
                setSelectedOption(e.currentTarget.value);
            }
        },
        [props, setCustomValue]
    );

    let textEntry;
    let radios = props.options?.map((optionData, index) => {
        const key = `${props.model}_${optionData.value}_${index}`;
        const value =
            typeof optionData.value === "boolean"
                ? String(optionData.value)
                : optionData.value;
        let selected = selectedOption === value;
        if (optionData.manualEntry === true || optionData.custom === true) {
            if (selected) {
                textEntry = (
                    <textarea
                        className={styles.customValue}
                        onChange={onChangeCallback}
                        value={customValue}
                    ></textarea>
                );
            }
        }
        return (
            <div className={styles.radioControl} key={key}>
                <input
                    type="radio"
                    name={props.model}
                    value={value}
                    id={key}
                    key={key}
                    className={styles.radio}
                    checked={selected}
                    onChange={onChangeCallback}
                />
                <label htmlFor={key}>{optionData.label}</label>
            </div>
        );
    });

    const { model, onChange } = props;
    let defaultValue: any;
    if (
        (props.value === null || props.value === void 0) &&
        props.default !== null &&
        props.default !== void 0
    ) {
        defaultValue = props.default;
    }
    useEffect(() => {
        if (defaultValue) {
            onChange(model, defaultValue);
            setSelectedOption(defaultValue);
        }
    }, [model, defaultValue, onChange]);

    return (
        <div className={styles.RadioButtons}>
            <p className={formStyles.label}>{props.label}</p>
            {radios}
            {textEntry}
            {error}
        </div>
    );
}
