import { get, del, clear, keys, createStore, set } from "idb-keyval";

const STORE_KEY = "redux-persist-store";
const persistStore = createStore("redux-persist", "redux-persist-store");

export default class IDBPersistStore {
    storeKey = STORE_KEY;

    async setItem(key: string, value: any) {
        return set(key, value, persistStore);
    }

    async getItem(key: string) {
        return await get(key, persistStore);
    }

    async removeItem(key: string) {
        return del(key, persistStore);
    }

    async getAllKeys() {
        return await keys(persistStore);
    }

    async clear() {
        return clear();
    }
}
