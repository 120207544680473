import { useCallback } from "react";
import Selector from "features/shared/Selector";
import { appDispatch } from "app/util";
import { setShowVenueSelect } from "app/appSlice";
import useVenueSelect from "features/header/useVenueSelect";

interface Props extends React.HTMLAttributes<HTMLSelectElement> {}

export default function VenueSelect(props: Props) {
    const { venues, activeVenueId, selectVenueId } = useVenueSelect();
    const items =
        venues?.map((venue) => ({
            id: venue.id,
            label: venue.name,
            value: venue.id,
            custom: false,
        })) || [];
    const selectId = useCallback(
        (id: number | undefined) => {
            selectVenueId(id);
            appDispatch(setShowVenueSelect(false));
        },
        [selectVenueId]
    );
    const dismiss = () => {
        appDispatch(setShowVenueSelect(false));
    };
    return (
        <div className={props.className}>
            <Selector
                activeId={activeVenueId || 0}
                items={items}
                selectId={selectId}
                dismiss={dismiss}
            />
        </div>
    );
}
