import { useAppSelector } from "app/hooks";

interface DiaryScreenData {
    onSubmit: (note: string) => void;
}

const onSubmit = () => {

}

export const useDiaryScreen = (): DiaryScreenData => {
    return {
        onSubmit
    }
}