import { createSelector } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Environment } from "features/environment/types";

export const envApi = createApi({
    reducerPath: "envApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
    }),
    endpoints: (builder) => ({
        getEnv: builder.query<Environment, void>({
            query: (data) => "env.json",
        }),
    }),
});

const selectEnvResult = envApi.endpoints.getEnv.select();
export const selectDownloadedEnv = createSelector(
    selectEnvResult,
    (envResult) => envResult?.data
);

export const { useGetEnvQuery } = envApi;
