import {
    BadgeCheck,
    BowlRice,
    CommentQuote,
    Droplet,
    FaceThermometer,
    FireSmoke,
    PawSimple,
    PenToSquare,
    Snowflake,
    Sparkles,
    SquareCheck,
    TemperatureHalf,
    TemperatureList,
    TemperatureSnow,
    TemperatureSun,
    TriangleExclamation,
    Truck,
    Virus,
    Wrench,
} from "icons/icons";

const icons = {
    BadgeCheck,
    BowlRice,
    CommentQuote,
    Droplet,
    FaceThermometer,
    FireSmoke,
    PawSimple,
    PenToSquare,
    Snowflake,
    Sparkles,
    SquareCheck,
    TemperatureHalf,
    TemperatureList,
    TemperatureSnow,
    TemperatureSun,
    TriangleExclamation,
    Truck,
    Virus,
    Wrench,
};

export default icons;
