import type { DiaryEntry } from "features/tasks/types";

const STORE_KEY = "chomp_redundant_store";

export type RedundantStoreData = {
    venueId?: number;
    fernetToken?: string;
    diaryQueue?: DiaryEntry[];
};

export default abstract class RedundantStore {
    storeKey = STORE_KEY;
    abstract setItem<K extends keyof RedundantStoreData>(
        key: K,
        value: RedundantStoreData[K]
    ): void;
    abstract getRedundantStore(): Promise<RedundantStoreData>;
    abstract clear(): void;

    async getItem<K extends keyof RedundantStoreData>(key: K) {
        const store = await this.getRedundantStore();
        return store[key];
    }

    setRedundantVenueId(id?: number) {
        this.setItem("venueId", id);
    }

    getRedundantVenueId(): Promise<number | undefined> {
        return this.getItem("venueId");
    }

    setRedundantFernetToken(token?: string) {
        this.setItem("fernetToken", token);
    }

    getRedundantFernetToken(): Promise<string | undefined> {
        return this.getItem("fernetToken");
    }

    setRedundantDiaryQueue(queue: DiaryEntry[]) {
        this.setItem("diaryQueue", queue);
    }

    getRedundantDiaryQueue(): Promise<DiaryEntry[] | undefined> {
        return this.getItem("diaryQueue");
    }
}
