import React from "react";
import GroupChecklistItem from "./GroupChecklistItem";
import type { TaskData } from "./types";
import styles from "./GroupChecklist.module.css";
import TasksOverlay from "./TasksOverlay";
import { groupName, TaskObjectsFunction } from "./functions";
import useGroupChecklist from "./useGroupChecklist";
import { sortTasks, getUrgency } from "./functions";

export interface Props {
    group: TaskData[];
    getTaskObjects: TaskObjectsFunction;
}

export default function GroupChecklist(props: Props) {
    const { checklistStatus, onCheck, onDone, onCompleteAll, onClose } =
        useGroupChecklist(props.group);
    let items = [];
    let sortedGroup = sortTasks(
        props.group,
        "overdueTime",
        "template_name",
        "name"
    );
    for (let task of sortedGroup) {
        let checked = checklistStatus[task.id] ?? false;
        let urgency = getUrgency(task);
        items.push(
            <li key={task.id}>
                <GroupChecklistItem
                    task={task}
                    checked={checked}
                    urgency={urgency}
                    onCheck={onCheck}
                />
            </li>
        );
    }

    let title = groupName(props.group, props.getTaskObjects);

    return (
        <TasksOverlay
            title={title}
            onDone={onDone}
            onClose={onClose}
            onCompleteAll={onCompleteAll}
        >
            <ul className={styles.list}>{items}</ul>
        </TasksOverlay>
    );
}
