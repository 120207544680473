import classnames from "classnames";
import { getLastPerformedMessage } from "./functions";
import styles from "./TaskGroupPopover.module.scss";
import iconStyles from "icons/Icon.module.css";
import { getFormattedDuration } from "./functions";
import { Clock, Calendar } from "icons/icons";
import { TaskData, DiaryEntry } from "./types";
import { PopoverInformation } from "features/environment/types";

interface Props {
    task: TaskData;
    status?: string;
    showPopoverInformation?: PopoverInformation;
    recentDiaries?: DiaryEntry[];
    getDiaryEntryHasErrors?: (
        task: TaskData,
        diaryEntry: DiaryEntry
    ) => boolean;
}

export default function TaskInfo(props: Props) {
    let timeLeftDuration;
    let icon;
    let hasErrors = false;
    let classes = [styles.titleContent];
    if (props.status) {
        classes.push(styles[props.status]);
    }
    const now = new Date().getTime() / 1000;
    if (props.task.nextDueTime) {
        const maxDays = 0;
        let duration = props.task.nextDueTime - now;
        if (duration > 0 && duration < 24 * 3600) {
            icon = <Clock className={iconStyles.smallIcon} />;
        } else if (duration > 24 * 3600) {
            icon = <Calendar className={iconStyles.smallIcon} />;
        }
        if (props.status === "due" || props.status === "overdue") {
            timeLeftDuration = getFormattedDuration(
                props.task.nextDueTime,
                maxDays * (24 * 60 * 60),
                true
            );
            if (timeLeftDuration) timeLeftDuration = timeLeftDuration + " left";
        }
    } else if (props.status) {
    }

    let lastPerformedData: string | undefined;
    if (
        props.task.performed &&
        props.showPopoverInformation &&
        props.getDiaryEntryHasErrors
    ) {
        let informationData: string | undefined;
        if (props.recentDiaries) {
            let recentDiary: DiaryEntry | undefined;
            for (let diary of props.recentDiaries) {
                if (
                    diary.taskId === props.task.id &&
                    (!recentDiary || diary.performed > recentDiary.performed)
                ) {
                    recentDiary = diary;
                }
            }
            if (recentDiary) {
                hasErrors = props.getDiaryEntryHasErrors(
                    props.task,
                    recentDiary
                );
                const information = JSON.parse(recentDiary.information);
                if (props.showPopoverInformation.model in information) {
                    informationData =
                        information[props.showPopoverInformation.model];
                    if (props.showPopoverInformation.suffix) {
                        informationData += props.showPopoverInformation.suffix;
                    }
                }
            }
        }
        let titleContent = [];
        if (informationData) {
            titleContent.push(informationData);
        }
        const lastPerformedMessage = getLastPerformedMessage(
            props.task.performed
        );
        if (lastPerformedMessage) {
            titleContent.push(lastPerformedMessage);
        }
        lastPerformedData = titleContent.join(" – ");
        classes.push(getPerformedClass(props.task.performed, hasErrors));
    }

    return (
        <div className={classnames(...classes)}>
            {icon && <span className={styles.icon}>{icon}</span>}
            {timeLeftDuration && timeLeftDuration}
            {lastPerformedData}
        </div>
    );
}

function getPerformedClass(performed: number, hasErrors: boolean): string {
    // TODO: create date using timezone of venue?
    const nowDate = new Date();
    const now =
        Date.UTC(
            nowDate.getFullYear(),
            nowDate.getMonth(),
            nowDate.getDate(),
            nowDate.getHours(),
            nowDate.getMinutes(),
            nowDate.getSeconds()
        ) / 1000;
    const duration = now - performed;
    const durationMinutes = Math.round(duration / 60);
    let className = styles.lastPerformed;
    if (durationMinutes > 360) {
        className = styles.lastPerformedHide;
    } else if (durationMinutes > 120 || hasErrors) {
        className = styles.lastPerformedWarn;
    }

    return className;
}
