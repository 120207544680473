import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { TaskData, TaskPerformedData, Category, DiaryEntry } from "./types";
import { CategoryView } from "features/environment/types";
import { UUID } from "types";
import type { VenuePayloadAction } from "app/types";

type taskId = number;
type venueId = number;

export interface TasksState {
    tasks: Record<venueId, EntityState<TaskData, number>>;
    tasksPerformed: Record<venueId, EntityState<TaskPerformedData, number>>;
    categories: Record<venueId, EntityState<Category, number>>;
    taskDiaryEntries: Record<taskId, DiaryEntry[]>;
    diaryEntries: Record<venueId, Record<UUID, DiaryEntry>>;
    taskConfigs: Record<taskId, CategoryView>;
    recentRecordNames: Record<venueId, Record<taskId, string[]>>;
}

const tasksDataAdapter = createEntityAdapter<TaskData>();
const tasksPerformedDataAdapter = createEntityAdapter<
    TaskPerformedData,
    number
>({
    selectId: (performed) => performed.taskId,
});
const categoriesAdapter = createEntityAdapter<Category>();

const initialState: TasksState = {
    tasks: {},
    tasksPerformed: {},
    categories: {},
    taskDiaryEntries: {},
    diaryEntries: {},
    taskConfigs: {},
    recentRecordNames: {},
};

// How long should diaries be kept for
export const DEFAULT_DIARY_CUTOFF =
    new Date().getTime() - 60 * 60 * 24 * 7 * 1000; // 7 days

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        setTask: (state: TasksState, action: VenuePayloadAction<TaskData>) => {
            let tasks =
                state.tasks[action.payload.venueId] ||
                tasksDataAdapter.getInitialState();
            state.tasks[action.payload.venueId] = tasksDataAdapter.setOne(
                tasks,
                action.payload.data
            );
        },
        setTasks: (
            state: TasksState,
            action: VenuePayloadAction<TaskData[]>
        ) => {
            state.tasks[action.payload.venueId] = tasksDataAdapter.setAll(
                state.tasks[action.payload.venueId] ||
                    tasksDataAdapter.getInitialState(),
                action.payload.data
            );
        },
        setTaskPerformed: (
            state: TasksState,
            action: VenuePayloadAction<TaskPerformedData>
        ) => {
            let tasksPerformed =
                state.tasksPerformed[action.payload.venueId] ||
                tasksPerformedDataAdapter.getInitialState();
            state.tasksPerformed[action.payload.venueId] =
                tasksPerformedDataAdapter.setOne(
                    tasksPerformed,
                    action.payload.data
                );
        },
        setTasksPerformed: (
            state: TasksState,
            action: VenuePayloadAction<TaskPerformedData[]>
        ) => {
            state.tasksPerformed[action.payload.venueId] =
                tasksPerformedDataAdapter.setAll(
                    state.tasksPerformed[action.payload.venueId] ||
                        tasksPerformedDataAdapter.getInitialState(),
                    action.payload.data
                );
        },
        updateTask: (
            state: TasksState,
            action: VenuePayloadAction<TaskData>
        ) => {
            let tasks =
                state.tasks[action.payload.venueId] ||
                tasksDataAdapter.getInitialState();
            state.tasks[action.payload.venueId] = tasksDataAdapter.setOne(
                tasks,
                action.payload.data
            );
        },
        updateTasks: (
            state: TasksState,
            action: VenuePayloadAction<Record<number, TaskData>>
        ) => {
            let tasks =
                state.tasks[action.payload.venueId] ||
                tasksDataAdapter.getInitialState();
            state.tasks[action.payload.venueId] = tasksDataAdapter.setMany(
                tasks,
                action.payload.data
            );
        },
        setCategories: (
            state: TasksState,
            action: VenuePayloadAction<Category[]>
        ) => {
            let categories =
                state.categories[action.payload.venueId] ||
                categoriesAdapter.getInitialState();
            state.categories[action.payload.venueId] = categoriesAdapter.setAll(
                categories,
                action.payload.data
            );
        },
        setTaskDiaryEntries: (
            state: TasksState,
            action: PayloadAction<[taskId, DiaryEntry[]]>
        ) => {
            let [taskId, diaryEntries] = action.payload;
            state.taskDiaryEntries[taskId] = diaryEntries;
        },
        addTaskDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            const diaryEntries = action.payload;

            outerLoop: for (let diaryEntry of diaryEntries) {
                const taskId = diaryEntry.taskId;
                if (!state.taskDiaryEntries[taskId]) {
                    state.taskDiaryEntries[taskId] = [];
                }
                for (let diary of state.taskDiaryEntries[taskId]) {
                    if (diary.uuid === diaryEntry.uuid) {
                        // skip updating state.taskDiaryEntries below
                        continue outerLoop;
                    }
                }
                state.taskDiaryEntries[taskId].push(diaryEntry);
            }
        },
        removeTaskDiaryEntriesByDate: (
            state: TasksState,
            action: PayloadAction<number>
        ) => {
            const cutoff = action.payload;
            for (let taskId in state.taskDiaryEntries) {
                state.taskDiaryEntries[taskId] = state.taskDiaryEntries[
                    taskId
                ].filter((diary) => {
                    let performedOn: string | number | undefined =
                        diary.performedOn;
                    if (!performedOn && diary.performed)
                        performedOn = diary.performed * 1000;
                    return (
                        performedOn && new Date(performedOn).getTime() > cutoff
                    );
                });
            }
        },
        setDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            if (action.payload.length === 0) return;
            const diaryEntry = action.payload[0];
            const venueId = diaryEntry.venueId;
            let diariesObject: Record<UUID, DiaryEntry> = {};
            diariesObject = action.payload.reduce((prevValue, currentValue) => {
                prevValue[currentValue.uuid] = currentValue;
                return prevValue;
            }, diariesObject);

            state.diaryEntries[venueId] = diariesObject;
        },
        addDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            if (action.payload.length === 0) return;

            const diaryEntry = action.payload[0];
            const venueId = diaryEntry.venueId;
            let diariesObject: Record<UUID, DiaryEntry> = {};
            diariesObject = action.payload.reduce((prevValue, currentValue) => {
                prevValue[currentValue.uuid] = currentValue;
                return prevValue;
            }, diariesObject);

            if (!state.diaryEntries[venueId]) {
                state.diaryEntries[venueId] = {};
            }
            state.diaryEntries[venueId] = Object.assign(
                state.diaryEntries[venueId],
                diariesObject
            );
        },
        removeDiaryEntries: (
            state: TasksState,
            action: PayloadAction<[number, DiaryEntry[]]>
        ) => {
            const [venueId, diaryEntries] = action.payload;
            if (venueId in state.diaryEntries) {
                for (let diary of diaryEntries) {
                    if (diary.uuid in state.diaryEntries[venueId]) {
                        delete state.diaryEntries[venueId][diary.uuid];
                    }
                }
            }
        },
        removeDiaryEntriesByDate: (
            state: TasksState,
            action: PayloadAction<number>
        ) => {
            const cutoff = action.payload;
            for (let venueId in state.diaryEntries) {
                state.diaryEntries[venueId] = Object.fromEntries(
                    Object.entries(state.diaryEntries[venueId]).filter(
                        ([uuid, diary]) => {
                            let performedOn: string | number | undefined =
                                diary.performedOn;
                            if (!performedOn && diary.performed)
                                performedOn = diary.performed * 1000;
                            return (
                                performedOn &&
                                new Date(performedOn).getTime() > cutoff
                            );
                        }
                    )
                );
            }
        },
        setRecentRecordNames: (
            state: TasksState,
            action: VenuePayloadAction<Record<number, string[]>>
        ) => {
            state.recentRecordNames[action.payload.venueId] =
                action.payload.data;
        },
        resetState: (state: TasksState, action: PayloadAction<undefined>) => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setTask,
    setTasks,
    updateTask,
    updateTasks,
    setTaskPerformed,
    setTasksPerformed,
    setCategories,
    setDiaryEntries,
    addDiaryEntries,
    setTaskDiaryEntries,
    addTaskDiaryEntries,
    removeTaskDiaryEntriesByDate,
    removeDiaryEntries,
    removeDiaryEntriesByDate,
    setRecentRecordNames,
} = tasksSlice.actions;

const tasksSelectors = tasksDataAdapter.getSelectors();
const tasksPerformedSelectors = tasksPerformedDataAdapter.getSelectors();
const categoriesSelectors = categoriesAdapter.getSelectors();

export const selectTasks = (
    state: RootState,
    venueId: number,
    taskIds?: number[]
): TaskData[] => {
    let tasks = tasksSelectors.selectAll(
        state.tasks.tasks[venueId] || tasksDataAdapter.getInitialState()
    );
    if (taskIds) {
        tasks = tasks.filter((task) => task.id in taskIds);
    }
    return tasks;
};
export const selectTask = (
    state: RootState,
    venueId: number,
    taskId: number
): TaskData | undefined => {
    let taskState =
        state.tasks.tasks[venueId] || tasksDataAdapter.getInitialState();
    if (taskId in taskState.entities) {
        return taskState.entities[taskId];
    } else {
        return void 0;
    }
};

export const selectTasksPerformed = (
    state: RootState,
    venueId: number
): TaskPerformedData[] | undefined => {
    return tasksPerformedSelectors.selectAll(
        state.tasks.tasksPerformed[venueId] ||
            tasksPerformedDataAdapter.getInitialState()
    );
};
export const selectTasksPerformedEntities = (
    state: RootState,
    venueId: number
): Record<number, TaskPerformedData> => {
    return tasksPerformedSelectors.selectEntities(
        state.tasks.tasksPerformed[venueId] ||
            tasksPerformedDataAdapter.getInitialState()
    );
};
export const selectTaskPerformed = (
    state: RootState,
    venueId: number,
    taskId: number
): TaskPerformedData | undefined => {
    if (venueId in state.tasks.tasksPerformed) {
        return state.tasks.tasksPerformed[venueId].entities[taskId];
    } else {
        return void 0;
    }
};

export const selectCategories = (state: RootState, venueId: number) =>
    categoriesSelectors.selectAll(
        state.tasks.categories[venueId] || categoriesAdapter.getInitialState()
    );
export const selectCategoryEntities = (state: RootState, venueId: number) =>
    categoriesSelectors.selectEntities(
        state.tasks.categories[venueId] || categoriesAdapter.getInitialState()
    );
export const selectCategory = (
    state: RootState,
    venueId: number,
    categoryId: number
) => {
    if (venueId in state.tasks.categories) {
        return state.tasks.categories[venueId].entities[categoryId];
    } else {
        return void 0;
    }
};

export const selectTaskDiaryEntries = (
    state: RootState,
    taskId: number
): DiaryEntry[] | undefined => {
    return state.tasks.taskDiaryEntries[taskId];
};

export const selectDiaryEntries = (
    state: RootState,
    venueId: number
): Record<UUID, DiaryEntry> | undefined => {
    return state.tasks.diaryEntries[venueId];
};
export default tasksSlice.reducer;

export const selectHasSetData = (state: RootState, venueId?: number) => {
    if (!venueId) return false;
    return (
        state.tasks.tasks[venueId] &&
        state.tasks.tasks[venueId] !== tasksDataAdapter.getInitialState() &&
        state.tasks.tasksPerformed[venueId] &&
        state.tasks.tasksPerformed[venueId] !==
            tasksPerformedDataAdapter.getInitialState() &&
        state.tasks.categories[venueId] &&
        state.tasks.categories[venueId] !== categoriesAdapter.getInitialState()
    );
};

export const selectRecentRecordNames = (
    state: RootState,
    venueId: number
): Record<number, string[]> | undefined => {
    return state.tasks.recentRecordNames[venueId];
};
