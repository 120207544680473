import React, { FormEvent } from "react";
import useTaskScreen from "features/tasks/useTaskScreen";
import {
    selectActiveTasksScreen,
    selectActiveTaskForm,
    setActiveTaskForm,
    setActiveTasksScreen,
} from "app/appSlice";
import TaskForm, { getDataSources } from "./form/TaskForm";
import validateForm from "./form/validateForm";
import TasksOverlay from "./TasksOverlay";
import styles from "./TaskScreen.module.css";
import { marked } from "marked";
import Error from "app/Error";

export default function TaskScreen() {
    const {
        task,
        activeUserId,
        hasTaskSignoff,
        hasBeenIdle,
        provingCount,
        batchNumber,
        onChange,
        onResetShowErrors,
        onSubmit,
        onError,
        onClose,
        onAddStoredItem,
        onRemoveStoredItem,
        formData,
        categoryView,
    } = useTaskScreen(
        selectActiveTasksScreen,
        selectActiveTaskForm,
        setActiveTaskForm,
        setActiveTasksScreen
    );
    if (!task || !formData || !categoryView) {
        return (
            <Error onConfirm={onClose}>
                Your plan is missing important information required to display
                this task (task/formData/categoryView)
            </Error>
        );
    }

    const onDone = (e?: FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();

        const dataSources = getDataSources(task);
        const [enabledModels, isValid, _problems, _required, ignore] =
            validateForm(formData, dataSources, task);

        if (!isValid) {
            onError();
            return;
        }

        let data: Record<string, any> = {};
        for (let model in enabledModels) {
            data[model] = enabledModels[model].value;
        }

        const ignoreKeys = Object.keys(ignore);
        if (ignore && ignoreKeys.length > 0) {
            for (let key of ignoreKeys) {
                delete data[key];
            }
        }

        onSubmit(data);
    };

    return (
        <TasksOverlay
            title={task.name}
            onDone={onDone}
            onClose={onClose}
            provingCount={provingCount}
            batchNumber={batchNumber}
        >
            <>
                {formData.formView.showMethod !== false && task.method && (
                    <p
                        className={styles.method}
                        dangerouslySetInnerHTML={{
                            __html: marked
                                .parse(task.method)
                                .replace(/\n/g, ""),
                        }}
                    />
                )}
            </>
            <TaskForm
                task={task}
                activeUserId={activeUserId}
                hasTaskSignoff={hasTaskSignoff}
                hasBeenIdle={hasBeenIdle}
                onChange={onChange}
                onResetShowErrors={onResetShowErrors}
                onSubmit={onSubmit}
                onError={onError}
                onAddStoredItem={onAddStoredItem}
                onRemoveStoredItem={onRemoveStoredItem}
                formData={formData}
                categoryView={categoryView}
            />
        </TasksOverlay>
    );
}
