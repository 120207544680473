import { useAppSelector } from "app/hooks";
import { selectIsLoggedIn } from "features/loginToken/loginTokenSlice";
import styles from "./SetPasswordScreen.module.scss";

export default function SetPasswordSuccess() {
    const loggedIn = useAppSelector(selectIsLoggedIn);
    return (
        <div className={styles.SetPasswordScreen}>
            {!loggedIn && (
                <div className={styles.logoContainer}>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </div>
            )}

            <p>You have successfully set your new password.</p>
            <p>
                <a href="/">Start using Chomp.</a>
            </p>
        </div>
    );
}
