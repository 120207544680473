import { ReactNode } from "react";
import classnames from "classnames";
import { useAppSelector } from "app/hooks";
import styles from "./Header.module.scss";
import { selectUserVenues } from "features/loginToken/loginTokenSlice";
import { selectShowVenueSwitcher } from "features/environment/envSlice";
import { setShowVenueSelect } from "app/appSlice";
import { useCallback } from "react";
import { appDispatch } from "app/util";
import HeaderButton from "features/header/HeaderButton";

interface Props {
    children: ReactNode;
}

export default function VenueName(props: Props) {
    const userVenues = useAppSelector(selectUserVenues);
    const showVenueSwitcher = useAppSelector(selectShowVenueSwitcher);
    const clickable = showVenueSwitcher && userVenues && userVenues.length > 1;
    const onClick = useCallback(() => {
        if (clickable) {
            appDispatch(setShowVenueSelect(true));
        }
    }, [clickable]);
    let classes = [styles.venueName];
    if (clickable) {
        classes.push(styles.clickable);
    }
    return (
        <div className={classnames(...classes)} onClick={onClick}>
            {!clickable && <h3>{props.children}</h3>}
            {clickable && (
                <HeaderButton onClick={onClick}>{props.children}</HeaderButton>
            )}
        </div>
    );
}
