import React, { ChangeEvent } from "react";
import { StructuralComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./Row.module.scss";
import { marked } from "marked";

interface Props extends StructuralComponentProps {}

export default function Row(props: Props) {
    return <div className={styles.Row}>{props.components}</div>;
}
