import { User } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import type { Profile } from "features/loginToken/types";
import HeaderButton from "./HeaderButton";
import { useAppDispatch } from "app/hooks";
import { useCallback } from "react";
import { showRoute } from "app/routeListeners";

interface Props {
    profile: Profile;
}

export default function ProfileButton(props: Props) {
    const dispatch = useAppDispatch();
    const onClick = useCallback(() => {
        dispatch(showRoute("profile"));
    }, [dispatch]);
    return (
        <div>
            <HeaderButton onClick={onClick}>
                <User className={iconStyles.largeIcon} />{" "}
                {props.profile.firstName} {props.profile.lastName}
            </HeaderButton>
        </div>
    );
}
