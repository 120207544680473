import React, { forwardRef } from "react";
import classnames from "classnames";
import { DateTime } from "luxon";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./DateEntry.module.css";
import DatePicker from "react-datepicker";
import { Calendar } from "icons/icons";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends ComponentProps {}

const DATE_FORMAT = "yyyy-MM-dd";
const DISPLAY_DATE_FORMAT = "dd MMMM yyyy";

function getDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(DATE_FORMAT);
}

export default function DateEntry(props: Props) {
    let error;
    if (props.error || (props.problem && props.problemError) || (props.invalid && props.invalidError)) {
        let errorMessage;
        if (props.problem && props.problemError) {
            errorMessage = props.problemError;
            error = <p className={formStyles.problem}>{errorMessage}</p>;
        } else if (props.invalid && props.invalidError) {
            errorMessage = props.invalidError;
            error = <p className={formStyles.invalid}>{errorMessage}</p>;     
        } else {
            errorMessage = props.error?.message;
            error = <p className={formStyles.error}>{errorMessage}</p>;
        }
        
    }

    const onChange = (date: Date) => {
        props.onChange(props.model, getDate(date));
    };

    let date: Date | undefined = void 0;
    if (props.value && !(props.value instanceof Date)) {
        date = DateTime.fromFormat(props.value, DATE_FORMAT).toJSDate();
    }

    const CustomInput = (
        props: React.HTMLProps<HTMLInputElement>,
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <div className={styles.inputContainer}>
                <input 
                    {...props} 
                    className={styles.input} 
                />
                <Calendar className={styles.calendar} />
            </div>
        );
    };

    let classes = [styles.DateEntry];
    if (props.problem) {
        classes.push(styles.problem);
    }

    return (
        <div className={classnames(...classes)}>
            <p
                dangerouslySetInnerHTML={{ __html: props.label || "" }}
                className={formStyles.label}
            ></p>
            <DatePicker
                onChange={onChange}
                selected={date}
                dateFormat={DISPLAY_DATE_FORMAT}
                customInput={React.createElement(React.forwardRef(CustomInput))}
            />

            {error}
        </div>
    );
}
