import React from "react";
import classnames from "classnames";
import { Device } from "icons/icons";
import styles from "./DeviceId.module.css";

import iconStyles from "icons/Icon.module.css";

interface Props {
    label: string;
}

export default function DeviceId(props: Props) {
    let classes = [styles.DeviceId];
    return (
        <div className={classnames(...classes)}>
            <Device
                className={classnames(
                    iconStyles.verySmallIcon,
                    iconStyles.detail
                )}
            />
            <h3>{props.label}</h3>
        </div>
    );
}
