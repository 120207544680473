import { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm, UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import {
    selectUrlPath,
    selectUrlsParams,
    selectServerErrors,
    setServerError,
} from "app/appSlice";
import type { ServerError } from "app/appSlice";
import { yearsAgoDate } from "app/util";
import { PayloadAction } from "@reduxjs/toolkit";

export type InviteProfileFormData = {
    firstName: string;
    lastName: string;
    email: string;
    mobile?: string;
    dob?: string;
    language?: string;
    country?: string;
    token: string;
};

export type InviteProfileData = {
    isRegistering: boolean;
    errors: FieldErrorsImpl<InviteProfileFormData>;
    email: string;
    mobile?: string;
    dob?: string;
    language?: string;
    country?: string;
    token?: string;
    serverError?: ServerError;
    maxDob: string;
    register: UseFormRegister<InviteProfileFormData>;
    onSubmit: () => void;
};

export function parseInviteToken(data: string): string | undefined {
    const regex = /.*\/invite\/([^/]+)\/?.*$/;
    const match = regex.exec(data);
    return match ? match[1] : void 0;
}

export const SubmittedProfileActionType = "createdProfile";
function createSubmittedProfileAction(
    data: InviteProfileFormData
): PayloadAction<InviteProfileFormData> {
    return {
        type: SubmittedProfileActionType,
        payload: data,
    };
}

export default function useInviteProfile(): InviteProfileData {
    const [isRegistering, setIsRegistering] = useState(false);
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<InviteProfileFormData>();
    const urlPath = useAppSelector(selectUrlPath);
    const urlParams = useAppSelector(selectUrlsParams);
    const serverErrors = useAppSelector(selectServerErrors);
    let serverError: ServerError | undefined;
    if (serverErrors && "invite" in serverErrors) {
        serverError = serverErrors["invite"];
        if (isRegistering) setIsRegistering(false);
    }
    const token = parseInviteToken(urlPath);

    const email = urlParams["email"] || "";
    const firstName = watch("firstName");
    const lastName = watch("lastName");
    const mobile = watch("mobile");
    const dob = watch("dob");
    const formData = {
        firstName,
        lastName,
        email,
        mobile,
        dob,
        token,
    };

    const tenYearsAgo = yearsAgoDate(10);

    const onSubmit = handleSubmit(() => {
        if (!formData.token) {
            console.error("Missing token or venueId: %o", formData);
            return;
        }
        dispatch(setServerError(["invite", void 0]));
        dispatch(
            createSubmittedProfileAction(formData as InviteProfileFormData)
        );
        setIsRegistering(true);
    });

    return {
        isRegistering,
        errors,
        email,
        mobile,
        dob,
        token,
        serverError,
        maxDob: tenYearsAgo,
        register,
        onSubmit,
    };
}
