import React from "react";
import Modal from "./Modal";
import styles from "./Loader.module.css";

interface Props {
    children: React.ReactElement | string;
}

export default function Loader(props: Props) {
    return (
        <Modal isOpaque={true}>
            <div className={styles.Loader}>
                <img src="/ChompSpinner.svg" />
                <p>{props.children}</p>
            </div>
        </Modal>
    );
}
