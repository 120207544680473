import type { AppStartListening } from "app/listeners";
import { loginTokenSlice } from "features/loginToken/loginTokenSlice";
import {
    selectDiaryEntryQueue,
    addDiaryEntriesToQueue,
    removeDiaryEntriesFromQueue,
    removeDiaryEntryFromQueue,
    appSlice,
} from "app/appSlice";
import { REHYDRATE, RehydrateAction } from "redux-persist";
import { RootState, getRedundantStore } from "app/store";
import handleRehydration from "./handleRehydration";

export const startRehydateListening = (startListening: AppStartListening) => {
    startListening({
        type: REHYDRATE,
        effect: async (action, listenerApi) => {
            const rehydrateAction = action as RehydrateAction;
            await handleRehydration(
                rehydrateAction,
                listenerApi.getState(),
                listenerApi.dispatch
            );
        },
    });
};

export const startSetTokenListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: loginTokenSlice.actions.setFernetToken,
        effect: (action, listenerApi) => {
            const token = action.payload;
            getRedundantStore().setRedundantFernetToken(token);
        },
    });

    startListening({
        actionCreator: loginTokenSlice.actions.resetState,
        effect: (action, listenerApi) => {
            getRedundantStore().setRedundantFernetToken();
        },
    });
};

export const startSetVenueListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: appSlice.actions.setActiveVenueId,
        effect: (action, listenerApi) => {
            getRedundantStore().setRedundantVenueId(action.payload);
        },
    });

    startListening({
        actionCreator: loginTokenSlice.actions.resetState,
        effect: (action, listenerApi) => {
            getRedundantStore().setRedundantVenueId();
        },
    });
};

function updateDiaryEntryQueue(state: RootState) {
    const diaryQueue = selectDiaryEntryQueue(state);
    getRedundantStore().setRedundantDiaryQueue(diaryQueue);
}

export const startAddDiaryEntryListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: addDiaryEntriesToQueue,
        effect: (action, listenerApi) => {
            updateDiaryEntryQueue(listenerApi.getState());
        },
    });
    startListening({
        actionCreator: removeDiaryEntryFromQueue,
        effect: (action, listenerApi) => {
            updateDiaryEntryQueue(listenerApi.getState());
        },
    });
    startListening({
        actionCreator: removeDiaryEntriesFromQueue,
        effect: (action, listenerApi) => {
            updateDiaryEntryQueue(listenerApi.getState());
        },
    });
};

const listeners = [
    startRehydateListening,
    startSetTokenListening,
    startSetVenueListening,
    startAddDiaryEntryListening,
];
export default listeners;
