import { useAppDispatch, useAppSelector } from "app/hooks";
import {
    setActiveScreen,
    selectActiveScreen,
    popActiveScreen,
    selectPreviousScreen,
    setActiveTasksScreen,
    selectShowSidebar,
    setShowSidebar,
    selectActiveVenueId,
    setActiveVenueId,
} from "app/appSlice";
import { selectVenueData } from "features/venue/venueSlice";
import {
    selectUserVenues,
    selectProfile,
} from "features/loginToken/loginTokenSlice";
import type { Profile } from "features/loginToken/types";
import { selectShowVenueSwitcher } from "features/environment/envSlice";
import { getStore } from "app/store";
import { ChangeEvent, useCallback } from "react";
import { getBuildVersion } from "app/util";
import { showRoute } from "app/routeListeners";
import type { UserVenueData } from "features/loginToken/types";

export interface HeaderData {
    activeVenueId?: number;
    userVenues?: UserVenueData[];
    venueName?: string;
    showBackButton?: boolean;
    showSidebar: boolean;
    buildVersion: string;
    showVenueSwitcher?: boolean;
    profile?: Profile;
    backButtonOnClick?: () => void;
    resetTaskScreen: () => void;
    adminOnClick: () => void;
    toggleSidebar: () => void;
    onChangeVenue: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const setAdminScreenActive = () => {
    getStore().dispatch(setActiveScreen("admin"));
};

const resetTaskScreen = () => {
    getStore().dispatch(setActiveTasksScreen({}));
};

export default function useHeader(): HeaderData {
    const activeVenueId = useAppSelector(selectActiveVenueId);
    const userVenues = useAppSelector(selectUserVenues);
    const venueData = useAppSelector((state) =>
        selectVenueData(state, activeVenueId)
    );
    const dispatch = useAppDispatch();
    const showSidebar = useAppSelector(selectShowSidebar);
    const buildVersion = getBuildVersion();
    const previousScreen = useAppSelector(selectPreviousScreen);
    const showVenueSwitcher = useAppSelector(selectShowVenueSwitcher);
    const profile = useAppSelector(selectProfile);
    let showBackButton = false;
    if (previousScreen) {
        showBackButton = true;
    }
    let venueName;
    if (venueData) {
        if (venueData.draft) {
            venueName = venueData.venueName + " - DRAFT";
        } else {
            venueName = venueData.venueName;
        }
    }

    const backButtonOnClick = useCallback(() => {
        dispatch(popActiveScreen());
        if (!previousScreen) {
            dispatch(showRoute("default"));
        } else {
            dispatch(showRoute(previousScreen));
        }
    }, [previousScreen, dispatch]);

    const toggleSidebar = useCallback(() => {
        dispatch(setShowSidebar(!showSidebar));
    }, [dispatch, showSidebar]);

    const onChangeVenue = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            dispatch(setActiveVenueId(parseInt(e.target.value)));
        },
        [dispatch]
    );

    return {
        activeVenueId,
        userVenues,
        venueName,
        showBackButton,
        showSidebar,
        buildVersion,
        showVenueSwitcher,
        profile,
        resetTaskScreen,
        backButtonOnClick: showBackButton ? backButtonOnClick : undefined,
        adminOnClick: setAdminScreenActive,
        toggleSidebar,
        onChangeVenue,
    };
}
