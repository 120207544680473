import { ComponentProps } from "./types";
import TextEntry from "./TextEntry";
import TextLineEntry from "./TextLineEntry";
import TimeEntry from "./TimeEntry";
import DateEntry from "./DateEntry";
import IntegerEntry from "./IntegerEntry";
import CheckListItem from "./ChecklistItem";
import TabOptions from "./TabOptions";
import RadioButtons from "./RadioButtons";
import NumberSlider from "./NumberSlider";
import Heading from "./Heading";
import Subheading from "./Subheading";
import DropdownList from "./DropdownList";
import StoredItemsList from "./StoredItemsList";
import Row from "./Row";
import ButtonComponent from "./ButtonComponent";
import PhotoUpload from "./PhotoUpload";
import AutoSelect from "./AutoSelect";

const formClasses: {
    [key: string]: (props: ComponentProps) => JSX.Element;
} = {
    TextEntry,
    TextLineEntry,
    TimeEntry,
    DateEntry,
    CheckListItem,
    TabOptions,
    RadioButtons,
    NumberSlider,
    IntegerEntry,
    Heading,
    Subheading,
    DropdownList,
    StoredItemsList,
    Row,
    Button: ButtonComponent,
    PhotoUpload,
    AutoSelect,
};

export default formClasses;
