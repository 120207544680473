import styles from "./CloseButton.module.css";
import iconStyles from "icons/Icon.module.css";
import { Xmark } from "icons/icons";
import classNames from "classnames";

type Props = {
    className?: string;
    onClick: () => void;
};

export default function CloseButton(props: Props) {
    let className = styles.CloseButton;
    if (props.className) className = classNames(className, props.className);
    return (
        <div className={className} onClick={props.onClick}>
            <Xmark className={iconStyles.smallIcon} />
        </div>
    );
}
