import React, { useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTasksScreen } from "./useTasksScreen";
import TaskScreen from "./TaskScreen";
import TaskGroup from "./TaskGroup";
import GroupChecklist from "./GroupChecklist";
import GroupTemperaturelist from "./GroupTemperatureList";
import {
    groupId,
    groupData,
    groupCategory,
    getGroupUrgency,
} from "./functions";
import styles from "./TasksScreen.module.scss";
import Loader from "app/Loader";
import { TaskData, TaskStatus, TaskUrgencies } from "./types";
import Modal from "app/Modal";
import VenueClosed from "./VenueClosed";
import { ChefEmpty } from "icons/icons";
import { Flipper } from "react-flip-toolkit";
import Error from "app/Error";

interface HandleEnterUpdateDeleteArgs {
    hideEnteringElements: () => void;
    animateExitingElements: () => Promise<void>;
    animateFlippedElements: () => Promise<void> | void;
    animateEnteringElements: () => void;
}

const exitThenFlipThenEnter = (args: HandleEnterUpdateDeleteArgs) => {
    args.hideEnteringElements();
    args.animateExitingElements()
        .then(args.animateFlippedElements)
        .then(args.animateEnteringElements);
};

const getGroupStatus = (group: TaskData[]): TaskStatus => {
    const urgency = getGroupUrgency(group);
    // if any task in the group has a schedule, consider
    // the category to be "due"
    const hasSchedule = group.some((task) => task.schedule !== undefined);
    switch (urgency) {
        case TaskUrgencies.URGENT:
            return "urgent";
        case TaskUrgencies.DUE:
            return hasSchedule ? "due" : "frequent";
        default:
            return "event";
    }
};

export default function TasksScreen() {
    const {
        tasksQueryState,
        urgentGroups,
        dueGroups,
        frequentGroups,
        eventGroups,
        upToDate,
        activeTasksScreen,
        activeCategoryView,
        venueIsClosed,
        getTaskObjects,
        handleGroupClick,
        dismissModal,
    } = useTasksScreen();

    const showPopover = useCallback(
        (group: TaskData[], status: TaskStatus): boolean => {
            let category = groupData(group, getTaskObjects);
            return !!(
                category &&
                category.id === activeTasksScreen.categoryId &&
                !activeCategoryView?.groupView &&
                activeTasksScreen.status === status &&
                !activeTasksScreen.taskId
            );
        },
        [activeTasksScreen, activeCategoryView, getTaskObjects]
    );

    // Have we got data loaded from cache?
    const totalGroups =
        dueGroups.length +
        urgentGroups.length +
        frequentGroups.length +
        eventGroups.length;
    if (
        totalGroups === 0 &&
        tasksQueryState &&
        !tasksQueryState.isFetching &&
        tasksQueryState.isLoading
    ) {
        const loadingMessage = "Loading tasks...";
        <Loader>{loadingMessage}</Loader>;
    }

    let venueClosedNotice = venueIsClosed ? <VenueClosed /> : undefined;

    let groups = [];
    let tasksFlipKey = "urgent_";
    if (!venueIsClosed) {
        for (let group of urgentGroups.concat(dueGroups, frequentGroups)) {
            let id = groupId(group);
            // possible fix for whitescreen issue
            if (id === undefined) continue;
            // set status of category
            let status = getGroupStatus(group);
            // build task group
            let taskGroup = (
                <TaskGroup
                    key={`${status}_` + id}
                    status={status}
                    group={group}
                    showPopover={showPopover(group, status)}
                    getTaskObjects={getTaskObjects}
                    handleClick={handleGroupClick}
                />
            );

            groups.push(taskGroup);
            tasksFlipKey += `_${id}_`;
        }
    }

    let eventTaskGroups = [];
    for (let group of eventGroups) {
        let id = groupId(group);
        // possible fix for whitescreen issue
        if (id === undefined) continue;
        eventTaskGroups.push(
            <TaskGroup
                key={id}
                status="event"
                group={group}
                showPopover={showPopover(group, "event")}
                getTaskObjects={getTaskObjects}
                handleClick={handleGroupClick}
            />
        );
    }

    let taskOverlay;
    if (activeTasksScreen.taskId) {
        taskOverlay = (
            <ErrorBoundary
                fallbackRender={({ error }) => {
                    console.log("taskOverlay error: %o", error);
                    return (
                        <Error onConfirm={dismissModal}>
                            <p>Something went wrong:</p>
                            <p>
                                <i>{error.message}</i>
                            </p>
                        </Error>
                    );
                }}
            >
                <TaskScreen />
            </ErrorBoundary>
        );
    } else if (
        activeTasksScreen.categoryId &&
        activeTasksScreen.status &&
        activeCategoryView
    ) {
        let groups;
        if (activeTasksScreen.status === "urgent") {
            groups = urgentGroups;
        } else if (activeTasksScreen.status === "due") {
            groups = dueGroups;
        }
        const group = groups?.filter((group) => {
            const category = groupCategory(group, getTaskObjects);
            return category && category.id === activeTasksScreen.categoryId;
        });

        if (group && group.length > 0) {
            if (activeCategoryView.groupView === "checkList") {
                taskOverlay = (
                    <GroupChecklist
                        group={group[0]}
                        getTaskObjects={getTaskObjects}
                    />
                );
            } else if (activeCategoryView.groupView === "temperatureList") {
                if (group.length > 0 && group[0].length > 0) {
                    const formView = group[0][0].configuration;
                    taskOverlay = (
                        <GroupTemperaturelist
                            group={group[0]}
                            getTaskObjects={getTaskObjects}
                            formView={formView}
                        />
                    );
                } else {
                    taskOverlay = (
                        <Error onConfirm={dismissModal}>
                            <p>There are no group tasks available.</p>
                        </Error>
                    );
                }
            }
        }
    }

    let animateChanges = true;
    let upToDateContainer;
    if (upToDate) {
        upToDateContainer = (
            <div className={styles.groupNotification}>
                <ChefEmpty />
                <p>New tasks will appear here when they’re due.</p>
            </div>
        );
    }

    let groupsContainer;
    if (animateChanges) {
        groupsContainer = (
            <Flipper
                flipKey={tasksFlipKey}
                handleEnterUpdateDelete={exitThenFlipThenEnter}
            >
                <div className={styles.dueScreen}>{groups}</div>
            </Flipper>
        );
    } else {
        groupsContainer = <div className={styles.dueScreen}>{groups}</div>;
    }

    return (
        <>
            {venueClosedNotice}
            {upToDateContainer}
            {groupsContainer}
            <h2 className={styles.groupHeading}>Complete When It Happens</h2>
            <div className={styles.dueScreen}>{eventTaskGroups}</div>

            {taskOverlay && (
                <Modal
                    dismiss={dismissModal}
                    contentClass={styles.modalContent}
                >
                    {taskOverlay}
                </Modal>
            )}
        </>
    );
}
