import React, { ReactNode, useLayoutEffect } from "react";
import styles from "./InviteUser.module.scss";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "features/tasks/form/Button";
import useInviteUser from "./useInviteUser";

interface Props {
    title?: string;
    children?: ReactNode;
    confirmTitle?: string;
}

export default function InviteUser(props: Props) {
    const { email, emailIsValid, onUpdateEmail, onInvite, onCancel } =
        useInviteUser();

    useLayoutEffect(() => {
        document.getElementById("email")?.focus();
    });

    return (
        <div className={styles.container}>
            <div className={styles.InviteUser}>
                <h2 className={styles.heading}>
                    Invite someone to Chomp
                    <div style={{ cursor: "pointer" }} onClick={onCancel}>
                        <Xmark className={iconStyles.smallIcon} />
                    </div>
                </h2>
                <div className={styles.content}>
                    <h3>Enter the person's email:</h3>
                    <div>
                        <input
                            className={styles.email}
                            type="email"
                            placeholder="Email address"
                            name="email"
                            value={email}
                            onChange={onUpdateEmail}
                            id="email"
                        />
                    </div>
                </div>
                <footer>
                    <Button
                        onClick={onCancel}
                        type="submit"
                        className={styles.cancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onInvite}
                        type="submit"
                        disabled={!emailIsValid}
                    >
                        Invite
                    </Button>
                </footer>
            </div>
        </div>
    );
}
