import Modal from "app/Modal";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import styles from "./DiaryQueue.module.scss";
import useDiaryQueue from "./useDiaryQueue";
import { pluralise } from "app/util";

type Props = {
    dismiss: () => void;
};

export default function DiaryQueue(props: Props) {
    let { diaryQueueEntries } = useDiaryQueue();
    return (
        <Modal dismiss={props.dismiss}>
            <div className={styles.DiaryQueue}>
                <h2 className={styles.heading}>
                    Diary Queue{" "}
                    <div style={{ cursor: "pointer" }} onClick={props.dismiss}>
                        <Xmark className={iconStyles.smallIcon} />
                    </div>
                </h2>
                <div className={styles.content}>
                    {diaryQueueEntries && (
                        <table className={styles.entries}>
                            <tbody>
                                {diaryQueueEntries?.map((entry) => (
                                    <tr key={entry.id} className={styles.entry}>
                                        <td>
                                            <strong>{entry.name}</strong>
                                        </td>
                                        <td>{entry.time}</td>
                                        <td>
                                            {entry.files > 0 && (
                                                <>
                                                    ({entry.files}{" "}
                                                    {pluralise(
                                                        "File",
                                                        entry.files
                                                    )}
                                                    )
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {!diaryQueueEntries && <p>No diaries waiting</p>}
                </div>
            </div>
        </Modal>
    );
}
