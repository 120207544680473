import { RehydrateAction } from "redux-persist";
import { AppDispatch, RootState, getRedundantStore } from "app/store";
import {
    selectFernetToken,
    setFernetToken,
} from "features/loginToken/loginTokenSlice";
import {
    selectDiaryEntryQueue,
    addDiaryEntriesToQueue,
    setActiveVenueId,
    selectActiveVenueId,
} from "app/appSlice";
import sendDebugData from "features/debug/sendDebugData";

export default async function handleRehydration(
    action: RehydrateAction,
    state: RootState,
    dispatch: AppDispatch
) {
    const redundantStore = getRedundantStore();
    let fernetToken = selectFernetToken(state);
    const storedToken = await redundantStore.getRedundantFernetToken();
    if (!fernetToken && storedToken) {
        fernetToken = storedToken;
        dispatch(setFernetToken(storedToken));
    } else if (fernetToken !== storedToken) {
        redundantStore.setRedundantFernetToken(fernetToken);
    }

    const diaryQueue = selectDiaryEntryQueue(state);
    const storedDiaryQueue = await redundantStore.getRedundantDiaryQueue();
    if (
        diaryQueue.length === 0 &&
        storedDiaryQueue &&
        storedDiaryQueue.length > 0
    ) {
        dispatch(addDiaryEntriesToQueue(storedDiaryQueue));
    }

    let activeVenueId = selectActiveVenueId(state);
    const storedVenueId = await redundantStore.getRedundantVenueId();
    if (!activeVenueId && storedVenueId) {
        activeVenueId = storedVenueId;
        dispatch(setActiveVenueId(activeVenueId));
    } else if (activeVenueId !== storedVenueId) {
        redundantStore.setRedundantVenueId(activeVenueId);
    }

    const error = action.err;
    if (error && fernetToken && activeVenueId) {
        // We can only send debug data if we have a venue id
        sendDebugData("REHYDRATE error", {
            message: error.message,
            stack: error.stack,
        });
    }
}
