import React, { useCallback } from "react";
import styles from "./Modal.module.css";
import classnames from "classnames";

interface Props {
    dismiss?: () => void;
    isOpaque?: boolean;
    children: React.ReactElement;
    contentClass?: string;
}

export default function Modal(props: Props) {
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (e.target === e.currentTarget) {
                if (props.dismiss) props.dismiss();
            }
        },
        [props]
    );

    let classes = [styles.Modal];
    if (props.isOpaque) classes.push(styles.opaque);

    return (
        <div className={classnames(...classes)} onClick={handleClick}>
            <div className={props.contentClass || styles.ModalContent}>
                {props.children}
            </div>
        </div>
    );
}
