import { appServiceApi, getServerError } from "services/appService";
import type { AppStartListening } from "app/listeners";
import {
    selectActiveVenueId,
    setServerError,
    addAppToast,
    setUrlPath,
} from "app/appSlice";
import { InvitedUserActionType } from "./useInviteUser";
import { SubmittedProfileActionType } from "./useInviteProfile";
import type { InviteProfileFormData } from "./useInviteProfile";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppToast } from "app/types";
import { createNetworkOperation } from "features/networkQueue/types";
import { queueNetworkOperation } from "app/appSlice";
import {
    createCompletedOperation,
    createFailedOperation,
} from "features/networkQueue/NetworkQueue";
import { showRoute } from "app/routeListeners";

export const startInviteListening = (startListening: AppStartListening) => {
    startListening({
        type: InvitedUserActionType,
        effect: (action, listenerApi) => {
            let payloadAction = action as PayloadAction<string>;
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId) return;

            const operation = createNetworkOperation<{
                email: string;
                venueId: number;
            }>("invite", {
                email: payloadAction.payload,
                venueId,
            });
            listenerApi.dispatch(queueNetworkOperation(operation));

            const toast: AppToast = {
                id: `invite_success_` + new Date().getTime(),
                title: `Your invite is now being processed. It should be delivered shortly.`,
                created: new Date().toISOString(),
                type: "success",
                permanent: false,
                delay: 6000,
            };
            listenerApi.dispatch(addAppToast(toast));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.inviteUser.matchFulfilled,
        effect: (action, listenerApi) => {
            let args = action.meta.arg.originalArgs;
            let completedAction = createCompletedOperation(args);
            listenerApi.dispatch(completedAction);
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.inviteUser.matchRejected,
        effect: (action, listenerApi) => {
            let args = action.meta.arg.originalArgs;
            const error = getServerError(action);
            let failedAction = createFailedOperation(args, error);
            listenerApi.dispatch(failedAction);

            if (error?.httpCode === 412) {
                const errorData = JSON.parse(error.data);
                const toast: AppToast = {
                    id: `invite_failure_` + new Date().getTime(),
                    title: errorData.description || "Invite failed",
                    created: new Date().toISOString(),
                    type: "error",
                    permanent: true,
                };
                listenerApi.dispatch(addAppToast(toast));
            }
        },
    });
};

export const startInviteProfileListening = (
    startListening: AppStartListening
) => {
    startListening({
        type: SubmittedProfileActionType,
        effect: (action, listenerApi) => {
            let payloadAction = action as PayloadAction<InviteProfileFormData>;
            let profileData = {
                token: payloadAction.payload.token,
                first_name: payloadAction.payload.firstName,
                last_name: payloadAction.payload.lastName,
                email: payloadAction.payload.email,
                mobile: payloadAction.payload.mobile,
                dob: payloadAction.payload.dob,
            };

            listenerApi.dispatch(
                appServiceApi.endpoints.createUserProfile.initiate(profileData)
            );
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.createUserProfile.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(showRoute("inviteSuccess"));
            listenerApi.dispatch(setServerError(["invite", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.createUserProfile.matchRejected,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(
                setServerError(["invite", getServerError(action)])
            );
        },
    });
};

const listeners = [startInviteListening, startInviteProfileListening];
export default listeners;
