import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { VenueData } from "./types";
import type { VenuePayloadAction } from "app/types";

export interface VenueState {
    data?: VenueData;
    id?: number;
}

const initialState: Record<number, VenueState> = {};

export const venueSlice = createSlice({
    name: "venue",
    initialState,
    reducers: {
        setId: (
            state: typeof initialState,
            action: VenuePayloadAction<number>
        ) => {
            let venueData = state[action.payload.venueId] || {};
            state[action.payload.venueId] = {
                ...venueData,
                id: action.payload.venueId,
            };
        },
        setData: (
            state: typeof initialState,
            action: VenuePayloadAction<VenueData>
        ) => {
            let venueData = state[action.payload.venueId] || {};
            state[action.payload.venueId] = {
                ...venueData,
                data: action.payload.data,
            };
        },
        resetState: (
            state: typeof initialState,
            action: PayloadAction<undefined>
        ) => {
            return initialState;
        },
    },
});

export const { setId, setData } = venueSlice.actions;

export const selectVenueId = (
    state: RootState,
    venueId?: number
): number | undefined => {
    return state.venue[venueId || 0]?.id;
};
export const selectVenueData = (
    state: RootState,
    venueId?: number
): VenueData | undefined => {
    return venueId && state.venue[venueId] ? state.venue[venueId].data : void 0;
};

export const selectHasTaskSignoff = (
    state: RootState,
    venueId: number
): boolean | undefined => {
    return venueId && state.venue[venueId]
        ? state.venue[venueId].data?.hasTaskSignoff
        : void 0;
};
export const selectHasSetData = (state: RootState, venueId?: number) => {
    return selectVenueData(state, venueId) !== void 0;
};

export default venueSlice.reducer;
