import React, { useState, useCallback, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import type { ComponentProps, ComponentOption } from "./types";
import formStyles from "./Form.module.css";
import styles from "./TabOptions.module.css";
import iconStyles from "icons/Icon.module.css";
import { Xmark } from "icons/icons";

export default function TabOptions(props: ComponentProps) {
    const [hasResetValue, setHasResetValue] = useState<boolean>(false);
    let inputs: JSX.Element[] = [];
    if (props.options) {
        inputs = props.options.map(
            (option: ComponentOption, index): JSX.Element => {
                let className = "";
                const value =
                    typeof option.value === "boolean"
                        ? String(option.value)
                        : option.value;
                // Highlight problem options when active
                if (props.problem && option.value === props.value)
                    className = styles.problem;
                return (
                    <Nav.Item key={"TabOption_" + index} className={className}>
                        <Nav.Link eventKey={value}>{option.label}</Nav.Link>
                    </Nav.Item>
                );
            }
        );
    }

    const { model, onChange } = props;
    const onChangeCallback = useCallback(
        (newValue: any) => {
            onChange(model, newValue);
        },
        [onChange, model]
    );

    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }

    function onSelect(eventKey: string | null) {
        if (eventKey) {
            let value: string | boolean = eventKey;
            if (value === "false") value = false;
            if (value === "true") value = true;

            onChangeCallback(value);
        }

        if (props.onClick) {
            props.onClick();
        }
    }

    function onClear() {
        onChangeCallback(null);
        setHasResetValue(true);
    }

    let activeKey =
        props.value !== null && props.value !== void 0 ? props.value : null;
    let defaultValue: any;
    if (
        !hasResetValue &&
        activeKey === null &&
        props.default !== void 0 &&
        props.default !== null
    ) {
        activeKey = props.default;
        defaultValue = props.default;
    }

    useEffect(() => {
        if (defaultValue) {
            onChangeCallback(defaultValue);
        }
    }, [defaultValue, onChangeCallback, model]);

    return (
        <div className={styles.TabOptions}>
            <p dangerouslySetInnerHTML={{ __html: props.label || "" }}></p>
            <div className={styles.control}>
                <Nav activeKey={activeKey} variant="pills" onSelect={onSelect}>
                    {inputs}
                </Nav>

                {activeKey !== null && (
                    <Xmark onClick={onClear} className={iconStyles.smallIcon} />
                )}
            </div>
            {error}
        </div>
    );
}
