import { get, update, clear } from "idb-keyval";
import RedundantStore, { RedundantStoreData } from "./RedundantStore";

export default class IDBRedundantStore extends RedundantStore {
    setItem<K extends keyof RedundantStoreData>(
        key: K,
        value: RedundantStoreData[K]
    ) {
        update(this.storeKey, (currentStore) => {
            const store = currentStore || {};
            store[key] = value;
            return store;
        });
    }

    async getRedundantStore(): Promise<RedundantStoreData> {
        let store = await get(this.storeKey);

        return store || {};
    }

    clear() {
        clear();
    }
}
