import React, { ReactNode, useLayoutEffect } from "react";
import classnames from "classnames";
import styles from "./TasksOverlay.module.scss";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "./form/Button";
import { doc } from "prettier";

interface Props {
    title: string;
    children: ReactNode;
    provingCount?: number;
    batchNumber?: number;
    onDone: () => void;
    onClose: () => void;
    onCompleteAll?: () => void;
}

export default function TasksOverlay(props: Props) {
    let provingCount = "";
    if (props.provingCount != null) {
        provingCount = ` – ${props.provingCount} of 3 Proving Records Completed`;
    }

    let batchNumber = "";
    if (props.batchNumber) {
        batchNumber = ` – batch ${props.batchNumber}`;
    }

    useLayoutEffect(() => {
        // On mobile, prevent scrollable task content scrolling
        // to the bottom of the view.
        const contentContainer = document.getElementsByClassName(
            styles.contentContainer
        )[0];
        if (contentContainer) contentContainer.scrollTo(0, 0);
    }, []);

    return (
        <div className={classnames(styles.TasksOverlay, "taskScreen")}>
            <h2 className={styles.heading}>
                {props.title}
                {provingCount}
                {batchNumber}
                {
                    <div style={{ cursor: "pointer" }} onClick={props.onClose}>
                        <Xmark className={iconStyles.smallIcon} />
                    </div>
                }
            </h2>
            <div className={styles.contentContainer}>
                <div className={styles.content}>{props.children}</div>
            </div>
            <footer>
                {props.onCompleteAll && (
                    <div
                        onClick={props.onCompleteAll}
                        className={styles.completeAll}
                    >
                        Complete All
                    </div>
                )}
                {!props.onCompleteAll && <div />}
                <Button onClick={props.onDone} type="submit">
                    Done
                </Button>
            </footer>
        </div>
    );
}
