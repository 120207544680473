import { useForm, UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { yearsAgoDate } from "app/util";
import { selectProfile } from "features/loginToken/loginTokenSlice";
import type { Profile } from "features/loginToken/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { languageCodes } from "app/util";

export type ProfileFormData = {
    firstName: string;
    lastName: string;
    email: string;
    mobile?: string;
    dob?: string;
    language?: string;
    country?: string;
    token: string;
};

interface ProfileFormViewData {
    profile?: Profile;
    errors: FieldErrorsImpl<ProfileFormData>;
    maxDob: string;
    defaultLanguage: string;
    register: UseFormRegister<ProfileFormData>;
    save: () => void;
}

export const UpdatedProfileActionType = "updatedProfile";
function createUpdatedProfileAction(profile: Profile): PayloadAction<Profile> {
    return {
        type: UpdatedProfileActionType,
        payload: profile,
    };
}

export default function useProfileForm(): ProfileFormViewData {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ProfileFormData>();
    const profile = useAppSelector(selectProfile);
    const firstName = watch("firstName");
    const lastName = watch("lastName");
    const email = watch("email");
    const mobile = watch("mobile");
    const dob = watch("dob");
    const country = watch("country");
    const language = watch("language");
    const formData = {
        firstName,
        lastName,
        email,
        mobile,
        dob,
        country,
        language,
    };

    const tenYearsAgo = yearsAgoDate(10);

    let defaultLanguage = navigator.language;
    if (!languageCodes.includes(defaultLanguage)) {
        navigator.languages.forEach((lang) => {
            if (languageCodes.includes(lang)) {
                defaultLanguage = lang;
            }
        });
    }

    const save = handleSubmit(() => {
        if (profile) {
            const updatedProfile = {
                id: profile.id,
                ...formData,
            };
            dispatch(createUpdatedProfileAction(updatedProfile));
        }
    });

    return {
        profile,
        errors,
        maxDob: tenYearsAgo,
        defaultLanguage,
        register,
        save,
    };
}
