import styles from "./InviteProfile.module.scss";

export default function InviteProfileSuccess() {
    return (
        <div className={styles.InviteProfile}>
            <div className={styles.logoContainer}>
                <img
                    src="/Chomp-Logo-simplified.png"
                    alt="Chomp"
                    className={styles.logo}
                />
            </div>
            <h2>Thank you!  Now confirm your email address.</h2>
            <p>You should receive an email that will ask that you confirm you email address.  Please follow the provided link to confirm you email address and set your profile password.</p>
            <p>Once you have confirmed your email address and set your password you will be able to access your Chomp app.</p>
        </div>
    );
}
