import styles from "./PasswordReset.module.scss";

export default function PasswordResetInitiated() {
    return (
        <div className={styles.PasswordReset}>
            <img
                src="/Chomp-Logo-simplified.png"
                alt="Chomp"
                className={styles.logo}
            />
            <h1>Password Reset Initiated</h1>
            <p>
                We've emailed you instructions for setting your password, if an
                account exists with the email you entered. You should receive
                them shortly.
            </p>
            <p>
                If you don't receive an email, please make sure you've entered
                the address you registered with, and check your spam folder.
            </p>
            <p>
                <a href="/">&lt; Return to the main screen.</a>
            </p>
        </div>
    );
}
