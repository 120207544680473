import {
    createApi,
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { selectEnv } from "features/environment/envSlice";
import type { Profile } from "features/loginToken/types";
import {
    selectFernetToken,
    selectBearerToken,
} from "features/loginToken/loginTokenSlice";

const authBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const env = selectEnv(api.getState() as RootState);

    // gracefully handle scenarios where data to generate the URL is missing
    if (!env || !("authServiceBaseUrl" in env)) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "No environment data received",
            },
        };
    }

    const authServiceBaseUrl = env.authServiceBaseUrl;

    if (args instanceof Object && args["method"] === "POST") {
        let body = args["body"];
        if ("grant_type" in body) {
            body["client_id"] = env.authClientId;
            body["client_secret"] = env.authClientSecret;
        }
    }

    return fetchBaseQuery({
        baseUrl: authServiceBaseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const fernetToken = selectFernetToken(state);
            if (fernetToken) {
                headers.append("Authorization", `Basic ${fernetToken}`);
            } else {
                const bearerToken = selectBearerToken(state);
                if (bearerToken) {
                    headers.append(
                        "Authorization",
                        `Bearer ${bearerToken.token}`
                    );
                }
            }

            return headers;
        },
    })(args, api, extraOptions);
};

interface Credential {
    username: string;
    password: string;
}

export interface TokenResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

interface BearerToken {
    token: string;
    expiresIn: number;
    refreshToken: string;
}

export const authServiceApi = createApi({
    reducerPath: "authServiceApi",
    baseQuery: authBaseQuery,
    endpoints: (builder) => ({
        getBearerToken: builder.query<BearerToken, Credential>({
            query: (credential) => {
                return {
                    url: "oauth/token",
                    method: "POST",
                    body: {
                        grant_type: "password",
                        username: credential.username,
                        password: credential.password,
                        scope: "read:venue",
                    },
                };
            },
            transformResponse: (response, meta) => {
                let tokenResponse = response as TokenResponse;

                return {
                    token: tokenResponse.access_token,
                    expiresIn: tokenResponse.expires_in,
                    refreshToken: tokenResponse.refresh_token,
                };
            },
        }),
        updateProfile: builder.query<void, Profile>({
            query: (profile) => {
                let profileForm = new FormData();
                profileForm.append("uuid", profile.id);
                profileForm.append("first_name", profile.firstName);
                profileForm.append("last_name", profile.lastName);
                profileForm.append("email", profile.email);
                if (profile.mobile)
                    profileForm.append("mobile", profile.mobile);
                if (profile.dob) profileForm.append("dob", profile.dob);
                if (profile.country)
                    profileForm.append("country", profile.country);
                if (profile.language)
                    profileForm.append("language", profile.language);

                return {
                    url: `profile/${encodeURIComponent(profile.id)}`,
                    method: "PUT",
                    body: profileForm,
                    responseHandler: "text",
                };
            },
        }),
    }),
});
