import { appDispatch } from "app/util";
import {
    NetworkOperation,
    NetworkOperationHandler,
    NetworkOperationType,
} from "./types";
import { removeNetworkOperation, setServerError } from "app/appSlice";
import { ServerError } from "app/appSlice";
import { PayloadAction } from "@reduxjs/toolkit";

export const CompletedOperationType = "completedNetworkOperation";
export const createCompletedOperation = <T>(
    operation: NetworkOperation<T>
): PayloadAction<NetworkOperation<T>> => {
    return {
        type: CompletedOperationType,
        payload: operation,
    };
};

export const FailedOperationType = "failedNetworkOperation";
export const createFailedOperation = <T>(
    operation: NetworkOperation<T>,
    error?: ServerError
): PayloadAction<NetworkOperationFailedInfo<T>> => {
    return {
        type: FailedOperationType,
        payload: {
            operation,
            error,
        },
    };
};

export type NetworkOperationFailedInfo<T = {}> = {
    operation: NetworkOperation<T>;
    error?: ServerError;
};

export default class NetworkQueue {
    private handlers: Partial<
        Record<NetworkOperationType, NetworkOperationHandler>
    > = {};
    private activeOperations: Partial<
        Record<NetworkOperationType, NetworkOperation>
    > = {};
    private processing: boolean = false;

    registerHandler(handler: NetworkOperationHandler) {
        this.handlers[handler.type] = handler;
    }

    isActive(operationType?: NetworkOperationType): boolean {
        if (operationType) {
            return !!this.activeOperations[operationType];
        } else {
            for (let type in this.activeOperations) {
                const operationType = type as NetworkOperationType;
                if (this.activeOperations[operationType]) return true;
            }

            return false;
        }
    }

    processQueue(
        queue: Partial<Record<NetworkOperationType, NetworkOperation[]>>
    ) {
        if (this.processing) return;

        this.processing = true;
        let removedOperations: NetworkOperation[] = [];
        for (const type of Object.keys(queue)) {
            const operationType = type as NetworkOperationType;
            if (this.isActive(operationType)) continue;
            let activeOperation;
            const operations = queue[operationType];
            if (
                operations &&
                operations.length > 0 &&
                this.handlers.hasOwnProperty(operationType)
            ) {
                const handler = this.handlers[operationType];
                if (handler) {
                    activeOperation = operations[0];
                    handler.processOperation(activeOperation);
                }
            }
            this.activeOperations[operationType] = activeOperation;
        }
        this.processing = false;

        return removedOperations;
    }

    completedOperation(operation: NetworkOperation) {
        const type = operation.type;
        appDispatch(removeNetworkOperation(operation));
        appDispatch(setServerError([type, void 0]));
        if (type in this.activeOperations) {
            const activeOperation = this.activeOperations[type];
            if (activeOperation?.id === operation.id) {
                this.activeOperations[type] = void 0;
            }
        }
    }

    failedOperation(info: NetworkOperationFailedInfo) {
        const { operation, error } = info;
        const type = operation.type;
        const removeCodes = [405, 412, 422];
        if (error?.httpCode && removeCodes.includes(error.httpCode)) {
            appDispatch(removeNetworkOperation(operation));
        }
        if (error) {
            appDispatch(setServerError([type, error]));
        }

        if (type in this.activeOperations) {
            const activeOperation = this.activeOperations[type];
            if (activeOperation?.id === operation.id) {
                this.activeOperations[type] = void 0;
            }
        }
    }
}
