import { ChangeEvent, useState, useCallback, useRef } from "react";
import { ComponentProps } from "./types";
import styles from "./PhotoUpload.module.scss";
import Button from "./Button";
import CameraCapture from "features/camera/CameraCapture";
import { Camera } from "icons/icons";
import ImageViewer from "features/imageViewer/ImageViewer";
import CloseButton from "features/shared/CloseButton";
import formStyles from "./Form.module.css";
import { dataURL } from "types";
import { generateUniqueId } from "features/environment/util";
import iconStyles from "icons/Icon.module.css";

export type Photo = {
    name: string;
    dataUrl: dataURL;
};

export default function PhotoUpload(props: ComponentProps) {
    let [showCamera, setShowCamera] = useState(false);
    let [showPhotoSrc, setShowPhotoSrc] = useState<string | null>(null);
    let photos = props.files || [];
    const photosRef = useRef(photos);

    const takePhoto = () => {
        setShowCamera(true);
    };

    const addPhoto = useCallback(
        (photo: Photo) => {
            photosRef.current = [
                ...photosRef.current,
                { name: photo.name, data: photo.dataUrl },
            ];

            let names = photosRef.current.map((p) => p.name);
            props.onChange(props.model, names, photosRef.current);
        },
        [props]
    );

    const addCapture = (photo: dataURL) => {
        const name = `${generateUniqueId()}.jpg`;
        addPhoto({
            name,
            dataUrl: photo,
        });
    };

    const deletePhoto = useCallback(
        (index: number) => {
            photosRef.current = photosRef.current.filter((_, i) => i !== index);
            let names = photosRef.current.map((p) => p.name);
            props.onChange(props.model, names, photosRef.current);
        },
        [props]
    );

    const selectPhoto = (e: ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files;
        if (files) {
            for (let file of Array.from(files)) {
                let reader = new FileReader();
                reader.onload = () => {
                    let data = reader.result as string;
                    const photo = {
                        name: file.name,
                        dataUrl: data,
                    };
                    addPhoto(photo);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }

    return (
        <div className={styles.PhotoUpload}>
            <div className={styles.heading}>
                <p className={formStyles.label}>{props.label}</p>
                {error}
            </div>

            <div className={styles.controls}>
                <Button
                    onClick={takePhoto}
                    icon={<Camera className={iconStyles.smallIcon} />}
                >
                    Take Photo
                </Button>
                <label htmlFor="photoUpload">Select photos...</label>
                <input
                    type="file"
                    accept="image/*"
                    id="photoUpload"
                    multiple
                    onChange={selectPhoto}
                />
            </div>
            <div className={styles.photos}>
                {props.files &&
                    props.files.map((photo, i) => {
                        return (
                            <div key={i} className={styles.photoContainer}>
                                <img
                                    src={photo.data}
                                    alt=""
                                    className={styles.photo}
                                    onClick={() => setShowPhotoSrc(photo.data)}
                                />
                                <CloseButton
                                    onClick={() => deletePhoto(i)}
                                    className={styles.close}
                                />
                            </div>
                        );
                    })}
            </div>
            {showCamera && (
                <CameraCapture
                    onDismiss={() => setShowCamera(false)}
                    onCapture={addCapture}
                />
            )}
            {showPhotoSrc && (
                <ImageViewer
                    src={showPhotoSrc}
                    onDismiss={() => setShowPhotoSrc(null)}
                />
            )}
        </div>
    );
}
