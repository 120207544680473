import { useAppSelector } from "app/hooks";
import { useCallback, useState } from "react";
import type { TaskData, DiaryEntry } from "./types";
import Scheduler from "./Scheduler";
import { generateUniqueId } from "features/environment/util";
import { selectTask, addDiaryEntries } from "./tasksSlice";
import {
    setActiveTasksScreen,
    setPendingDiaryEntries,
    setShowUserSelect,
} from "app/appSlice";
import { appSelect, appDispatch } from "app/util";
import { selectHasTaskSignoff } from "features/venue/venueSlice";
import { selectActiveVenueId } from "app/appSlice";
import { selectActiveUserId } from "app/util";

interface GroupTemperatureListData {
    temperatureListStatus: Record<number, {}>;
    showErrors: boolean | undefined;
    activeTaskId: number | undefined;
    activeTaskValue: number | string | undefined;
    onSet: (taskId: number, model: string, value: number) => void;
    onError: () => void;
    onSubmit: () => void;
    onClose: () => void;
}

export default function useGroupTemperatureList(
    group: TaskData[]
): GroupTemperatureListData {
    const [temperatureListStatus, setTemperatureStatus] = useState<
        Record<number, Record<any, any>>
    >(() => {
        let data: Record<number, {}> = {};
        for (let task of group) {
            if (task.source === "sensor") {
                let sensorData: Record<string, any> = {};
                if (task.reading) {
                    sensorData["temperature"] = task.reading.average;
                }
                data[task.id] = sensorData;
            }
        }
        return data;
    });
    const [activeTaskId, setActiveTaskId] = useState<number>();
    const [activeTaskValue, setActiveTaskValue] = useState<number | string>();
    const [showErrors, setShowErrors] = useState<boolean>();

    const venueId = useAppSelector(selectActiveVenueId);
    if (!venueId)
        throw new Error("Missing venue id in useGroupTemperatureList");

    const hasTaskSignoff = useAppSelector((state) =>
        selectHasTaskSignoff(state, venueId)
    );
    let activeUserId = useAppSelector((state) =>
        selectActiveUserId(state, venueId)
    );

    const onSet = useCallback(
        (taskId: number, model: string, value: number | string) => {
            temperatureListStatus[taskId] = {
                ...temperatureListStatus[taskId],
                [model]: value,
            };
            setTemperatureStatus({ ...temperatureListStatus });
            setActiveTaskId(taskId);
            setActiveTaskValue(value);
            setShowErrors(true);
        },
        [temperatureListStatus, setTemperatureStatus]
    );

    const onClose = useCallback(() => {
        appDispatch(setActiveTasksScreen({}));
    }, []);

    const onError = useCallback(() => {
        setShowErrors(true);
    }, [setShowErrors]);

    const onSubmit = useCallback(() => {
        let completedDiaries: DiaryEntry[] = [];
        let performed = Scheduler.getNowUtc();
        for (const [taskIdString, data] of Object.entries(
            temperatureListStatus
        )) {
            if (data && venueId) {
                const taskId = Number(taskIdString);
                const task = appSelect((state) =>
                    selectTask(state, venueId, taskId)
                );
                if (task) {
                    let lastDueCompleted;
                    if (task && task.schedule) {
                        const scheduler = new Scheduler(task);
                        lastDueCompleted = scheduler.getLastDueUtc();
                    } else {
                        lastDueCompleted = Scheduler.getNowUtc();
                    }
                    if (
                        task?.source === "sensor" &&
                        task.reading &&
                        task.reading.average
                    ) {
                        data["reading"] = {
                            average: task.reading.average,
                        };
                    }
                    completedDiaries.push({
                        uuid: generateUniqueId(),
                        taskId: taskId,
                        information: JSON.stringify(data),
                        venueId: venueId || 0,
                        performed,
                        due: lastDueCompleted,
                        userId: activeUserId,
                    });
                }
            }
        }

        if (hasTaskSignoff && !activeUserId) {
            appDispatch(setPendingDiaryEntries(completedDiaries));
            appDispatch(setShowUserSelect(true));
        } else {
            appDispatch(addDiaryEntries(completedDiaries));
            appDispatch(setActiveTasksScreen({}));
        }
    }, [temperatureListStatus, activeUserId, hasTaskSignoff, venueId]);

    return {
        temperatureListStatus: temperatureListStatus,
        showErrors,
        activeTaskId,
        activeTaskValue,
        onSet,
        onError,
        onClose,
        onSubmit,
    };
}
