import Form from "react-bootstrap/Form";
import styles from "./PasswordReset.module.scss";
import usePasswordReset from "./usePasswordReset";
import Button from "features/tasks/form/Button";
import loaderStyles from "app/Loader.module.css";
import FormErrorMessage from "app/FormError";

export default function PasswordReset() {
    const {
        email,
        emailIsValid,
        passwordResetState,
        errors,
        validateEmail,
        initiateReset,
        register,
    } = usePasswordReset();

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        initiateReset();
    };
    return (
        <div className={styles.PasswordReset}>
            <img
                src="/Chomp-Logo-simplified.png"
                alt="Chomp"
                className={styles.logo}
            />
            <p className={styles.instructions}>
                Forgotten your password? Enter your email address below, and
                we'll email instructions for setting a new one.
            </p>

            <Form onSubmit={onSubmit}>
                <Form.Group>
                    <Form.Label>Email address:</Form.Label>
                    <Form.Control
                        type="email"
                        defaultValue={email}
                        {...register("email", {
                            required: true,
                            validate: validateEmail,
                        })}
                    />
                    <FormErrorMessage error={errors.email} />
                </Form.Group>
                {!passwordResetState?.isLoading && (
                    <Button type="submit" disabled={!emailIsValid}>
                        Reset my password
                    </Button>
                )}
                {passwordResetState?.isLoading && (
                    <div className={styles.loginLoader}>
                        <div className={loaderStyles.Loader}>
                            <img
                                src="/ChompSpinner.svg"
                                alt="Initiating password reset..."
                            />
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}
