import React from "react";
import Webcam from "react-webcam";
import Modal from "app/Modal";
import type { dataURL } from "types";
import styles from "./CameraCapture.module.scss";
import Button from "features/tasks/form/Button";
import { Camera } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import dropdownStyles from "features/tasks/form/DropdownList.module.css";

type Props = {
    onDismiss: () => void;
    onCapture: (dataUrl: dataURL) => void;
};

export default function CameraCapture(props: Props) {
    const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            props.onCapture(imageSrc);
            props.onDismiss();
        }
    }, [webcamRef, props]);

    const [deviceId, setDeviceId] = React.useState("");
    const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);

    const handleDevices = React.useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices
                .filter(({ kind }) => kind === "videoinput")
                .reverse();
            setDevices(devices);
            setDeviceId(devices[0]?.deviceId || "");
        },
        [setDevices]
    );
    console.log(devices);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDeviceId(e.target.value);
    };

    let deviceList;
    if (devices.length > 0) {
        deviceList = (
            <select onChange={onChange} className={dropdownStyles.dropdown}>
                {devices.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label.replace(/[ ]?\(.+\)/, "")}
                    </option>
                ))}
            </select>
        );
    }

    let videoConstraints = {};
    if (deviceId) videoConstraints = { deviceId: deviceId };

    React.useEffect(() => {
        // Trigger user-permission dialog and only try to
        // enumerate devices if the user allows.
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(function (stream) {
                if (navigator?.mediaDevices?.enumerateDevices)
                    navigator?.mediaDevices
                        ?.enumerateDevices()
                        .then(handleDevices);
            })
            .catch(function (err) {
                console.log(
                    "User denied permission to use the camera: %o",
                    err
                );
            });
    }, [handleDevices]);

    return (
        <Modal dismiss={props.onDismiss}>
            <div className={styles.CameraCapture}>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                />
                <div className={styles.controls}>
                    <Button onClick={props.onDismiss} className={styles.cancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={capture}
                        icon={<Camera className={iconStyles.smallIcon} />}
                        className={styles.captureButton}
                    >
                        Capture
                    </Button>
                    {deviceList}
                </div>
            </div>
        </Modal>
    );
}
