import { rrulestr } from "rrule";
import { DateTime } from "luxon";
import { TaskData, Schedule, TaskPerformedData } from "./types";
import { timestamp } from "types";

/**
 * This should only be called with tasks whose template is not an event.
 */
export default class Scheduler {
    task: TaskData;
    taskPerformed?: TaskPerformedData;
    lastDue: timestamp | undefined;
    lastDueUTC: timestamp | undefined;
    nextDue: timestamp | undefined;
    isPerformed: boolean;
    isDue: boolean;
    overdueTime?: number;
    today: Date;

    constructor(
        task: TaskData,
        today = new Date(),
        taskPerformed?: TaskPerformedData
    ) {
        this.task = task;
        this.today = today;
        this.taskPerformed = taskPerformed;
        // we cache all these as very slow on old machines otherwise
        this.lastDue = this.getLastDue();
        this.isPerformed = this.getIsPerformed();
        this.isDue = this.getIsDue();
        this.overdueTime = this.getOverdueTime();
        this.nextDue = this.getNextDue();
    }

    getLastDue(): number | undefined {
        let lastDue;
        // loop through schedules until we find an occurrence
        if (this.task.recent_schedules) {
            for (
                var i = 0, len = this.task.recent_schedules.length;
                i < len;
                i++
            ) {
                lastDue = this.lastOccurrence(this.task.recent_schedules[i]);
                if (lastDue) {
                    break;
                }
            }

            if (lastDue) {
                let lastDueTS = lastDue.getTime();
                let createdAtTS = DateTime.fromSQL(
                    this.task.recent_schedules[i].created_at
                );

                lastDue =
                    lastDueTS > createdAtTS.toUnixInteger() ? lastDue : void 0;
            }
        }

        return lastDue ? Math.floor(lastDue.getTime() / 1000) : lastDue;
    }

    getNextDue(): number | undefined {
        // caclulate the time of the next occurence of this task
        let nextDue;
        if (this.task.recent_schedules) {
            for (
                var i = 0, len = this.task.recent_schedules.length;
                i < len;
                i++
            ) {
                nextDue = this.nextOccurrence(this.task.recent_schedules[i]);
                if (nextDue) {
                    break;
                }
            }
        }
        return nextDue ? Math.floor(nextDue.getTime() / 1000) : nextDue;
    }

    getOverdueTime(): number | undefined {
        let overdueTime;
        let overdueThreshold = this.overdueThreshold();
        if (overdueThreshold && this.lastDue) {
            overdueTime = this.lastDue + overdueThreshold;
        }

        return overdueTime;
    }

    getIsPerformed() {
        let lastDueCompleted = this.task.lastDueCompleted;
        if (this.taskPerformed?.lastDueCompleted)
            lastDueCompleted = this.taskPerformed?.lastDueCompleted;
        const lastDueUTC = this.getLastDueUtc();
        if (lastDueCompleted && lastDueCompleted === lastDueUTC) {
            return true;
        } else if (!this.lastDue && this.taskPerformed?.performed) {
            return true;
        } else if (
            lastDueUTC &&
            this.taskPerformed?.performed &&
            this.taskPerformed?.performed > lastDueUTC
        ) {
            return true;
        } else {
            return false;
        }
    }

    getIsDue() {
        if (!this.lastDue) return true;

        let timeSinceDue = this.getNow() - this.lastDue;
        let overdueThreshold = this.overdueThreshold();

        if (!overdueThreshold || timeSinceDue <= overdueThreshold) {
            // its due
            return true;
        } else {
            // its overdue
            return false;
        }
    }

    getNow() {
        return Math.floor(this.today.getTime() / 1000);
    }

    getLastDueUtc() {
        if (this.lastDueUTC) return this.lastDueUTC;

        let lastDue = this.lastDue;
        this.lastDueUTC = lastDue
            ? Math.floor(
                  Scheduler.localAsUtc(new Date(lastDue * 1000)).getTime() /
                      1000
              )
            : void 0;

        return this.lastDueUTC;
    }

    lastOccurrence(schedule: Schedule) {
        let rruleset = rrulestr(schedule.rruleset);
        let now = Scheduler.localAsUtc(this.today);
        let lastOccurrence: Date | undefined = rruleset.before(now);

        lastOccurrence = lastOccurrence
            ? Scheduler.utcAsLocal(lastOccurrence)
            : void 0;
        return lastOccurrence;
    }

    nextOccurrence(schedule: Schedule) {
        let rruleset = rrulestr(schedule.rruleset);
        let now = Scheduler.localAsUtc(this.today);
        let nextOccurrence: Date | undefined = rruleset.after(now);

        nextOccurrence = nextOccurrence
            ? Scheduler.utcAsLocal(nextOccurrence)
            : void 0;
        return nextOccurrence;
    }

    static utcAsLocal(date: Date) {
        let local = DateTime.fromJSDate(date)
            .setZone("UTC")
            .setZone("local", { keepLocalTime: true })
            .toJSDate();

        return local;
    }

    static localAsUtc(date: Date) {
        let utc = DateTime.fromJSDate(date)
            .setZone("local")
            .setZone("UTC", { keepLocalTime: true })
            .toJSDate();

        return utc;
    }

    static getNowUtc(date?: Date) {
        if (!date) date = new Date();
        return Math.floor(this.localAsUtc(date).getTime() / 1000);
    }

    overdueThreshold(): number | undefined {
        // default, doesnt go overdue
        let threshold;
        if (!this.task.schedule) return threshold;

        let freq = this.task.schedule.frequency_key;

        if (freq === "yearly") {
            // yearly tasks get 240 days
            threshold = 20736000;
        } else if (freq === "6monthly") {
            // 6 monthly tasks get 120 days
            threshold = 10368000;
        } else if (freq === "3monthly") {
            // 3 monthly tasks get 60 days
            threshold = 5184000;
        } else if (freq === "monthly") {
            // monthly tasks get 21 days
            threshold = 1814400;
        } else if (freq === "weekly") {
            // weekly tasks get 6 days
            threshold = 518400;
        } else if (freq === "daily") {
            let anniversary = this.task.schedule.anniversary_key;
            if (anniversary === "afternoon") {
                // Afternoon tasks only get 6 hours
                threshold = 21600;
            } else {
                // all other daily tasks get 7 hours
                threshold = 25200;
            }
        } else if (freq === "halfDaily") {
            // all half day tasks get 7 hours
            threshold = 25200;
        }

        return threshold;
    }
}
