import formStyles from "app/form.module.scss";

type FormError = {
    type: string | undefined;
    message?: string;
};

const getErrorMessage = (
    error: FormError | undefined,
    message?: string
): string => {
    let errorMessage = "";
    if (error && error.type === "required") {
        errorMessage = "This field is required";
    } else if (error && message) {
        errorMessage = message;
    } else if (error && error.message) {
        errorMessage = error.message;
    }

    return errorMessage;
};

interface Props {
    error?: FormError;
}

export default function FormErrorMessage(props: Props) {
    let error = <></>;
    if (props.error) {
        error = (
            <p className={formStyles.error}>{getErrorMessage(props.error)}</p>
        );
    }
    return error;
}
