import {
    NetworkOperation,
    NetworkOperationHandler,
    NetworkOperationType,
} from "features/networkQueue/types";
import { appServiceApi } from "services/appService";
import { Profile } from "features/loginToken/types";
import { getStore } from "app/store";

export default class ProfileOperationHandler extends NetworkOperationHandler {
    type = "profile" as NetworkOperationType;

    processOperation(operation: NetworkOperation) {
        const profileOperation = operation as NetworkOperation<Profile>;

        getStore().dispatch(
            appServiceApi.endpoints.updateProfile.initiate(profileOperation)
        );
    }
}
