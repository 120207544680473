import { NetworkOperationHandler } from "features/networkQueue/types";
import type {
    NetworkOperation,
    NetworkOperationType,
} from "features/networkQueue/types";
import { appServiceApi } from "services/appService";
import { getStore } from "app/store";

export default class InviteOperationHandler extends NetworkOperationHandler {
    type = "invite" as NetworkOperationType;

    processOperation(operation: NetworkOperation) {
        const inviteOperation = operation as NetworkOperation<{
            email: string;
            venueId: number;
        }>;
        getStore().dispatch(
            appServiceApi.endpoints.inviteUser.initiate(inviteOperation)
        );
    }
}
