import { appServiceApi, getServerError } from "services/appService";
import { VenueData } from "./types";
import { setData, setId } from "./venueSlice";
import { setServerError, selectActiveVenueId } from "app/appSlice";
import type { AppStartListening } from "app/listeners";

export const startVenueListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getVenueData.matchFulfilled,
        effect: (action, listenerApi) => {
            // Don't set data if not logged in to a venue or the
            // tasks venue is not the active venue
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId || action.meta.arg.originalArgs.venueId !== venueId)
                return;

            const venueData = action.payload as VenueData;
            listenerApi.dispatch(setData({ venueId, data: venueData }));
            listenerApi.dispatch(setId({ venueId, data: venueId }));
            listenerApi.dispatch(setServerError(["venue", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getVenueData.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const serverError = getServerError(action);
            listenerApi.dispatch(setServerError(["venue", serverError]));
        },
    });
};

const listeners = [startVenueListening];
export default listeners;
