import React from "react";
import styles from "./StoredItemsList.module.css";
import iconStyles from "icons/Icon.module.css";
import { ComponentProps } from "./types";
import { TrashCan } from "icons/icons";
import Button from "./Button";

export default function StoredItemsList(props: ComponentProps) {
    let items: React.ReactNode[] = [];
    if (Array.isArray(props.value)) {
        items = props.value.map((value, index) => {
            const onClick = () => {
                if (props.onRemoveStoredItem) props.onRemoveStoredItem(index);
            };
            const valueClass = value.issue ? styles.issue : styles.fine;
            let quantity;
            let unit;
            if (value.temperature) {
                quantity = value.temperature;
                unit = <>&deg;C</>;
            } else if (value.frozen !== undefined) {
                quantity = "Frozen";
            } else if (value.dryProblem !== undefined) {
                quantity = "Dry";
            }
            return (
                <ul
                    className={styles.item}
                    key={`stored_item_${index}_${value.type}`}
                >
                    <li>{value.description}</li>
                    <li>{value.batch}</li>
                    <li>
                        <span className={valueClass}>
                            {quantity}
                            {unit}
                        </span>
                    </li>
                    <li>
                        <Button onClick={onClick}>
                            <TrashCan className={iconStyles.smallIcon} />
                        </Button>
                    </li>
                </ul>
            );
        });
    }
    return <div className={styles.StoredItemsList}>{items}</div>;
}
