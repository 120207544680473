import React, { useCallback } from "react";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./ButtonComponent.module.css";
import Button from "./Button";
import classnames from "classnames";

export default function ButtonComponent(props: ComponentProps) {
    let onClick = props.onClick || (() => {});
    let classes = [styles.ButtonComponent];
    if (props.selfValue && props.selfValue === props.value)
        classes.push(styles.active);
    return (
        <Button onClick={onClick} className={classnames(...classes)}>
            {props.label || ""}
        </Button>
    );
}
