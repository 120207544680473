import { appSelect, appDispatch } from "app/util";
import { selectShowEmailLogin } from "features/environment/envSlice";
import { showRoute } from "app/routeListeners";

interface LoginButtonsData {
    showPasswordLogin?: () => void;
    showQrCodeLogin?: () => void;
}

export default function useLoginButtons(): LoginButtonsData {
    let showPasswordLogin;
    if (appSelect(selectShowEmailLogin)) {
        showPasswordLogin = () => {
            appDispatch(showRoute("loginEmail"));
        };
    }
    const showQrCodeLogin = () => {
        appDispatch(showRoute("loginQrcode"));
    };

    return {
        showPasswordLogin,
        showQrCodeLogin,
    };
}
