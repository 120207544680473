import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Supplier } from "./types";
import { VenuePayloadAction } from "app/types";

type venueId = number;

export interface SuppliersState {
    suppliers: Record<venueId, EntityState<Supplier, number>>;
}

const suppliersAdapter = createEntityAdapter<Supplier>();

const initialState: SuppliersState = {
    suppliers: {},
};

export const suppliersSlice = createSlice({
    name: "suppliers",
    initialState,
    reducers: {
        setSuppliers: (state, action: VenuePayloadAction<Supplier[]>) => {
            state.suppliers[action.payload.venueId] = suppliersAdapter.setAll(
                state.suppliers[action.payload.venueId] ||
                    suppliersAdapter.getInitialState(),
                action.payload.data
            );
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setSuppliers } = suppliersSlice.actions;

const supplierSelectors = suppliersAdapter.getSelectors();

export const selectSuppliers = (state: RootState, venueId?: number) => {
    let suppliers = suppliersAdapter.getInitialState();
    if (venueId && state.suppliers.suppliers[venueId]) {
        suppliers = state.suppliers.suppliers[venueId];
    }
    return supplierSelectors.selectAll(suppliers);
};

export const selectHasSetData = (state: RootState, venueId?: number) =>
    selectSuppliers(state, venueId) !== undefined;

export default suppliersSlice.reducer;
