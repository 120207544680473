import { appServiceApi } from "services/appService";
import {
    appSlice,
    setPendingDiaryEntry,
    selectPendingDiaries,
    selectPendingDiaryFiles,
    setPendingDiaryFiles,
    getActiveFormDataSetter,
    getActiveScreenSetter,
    setServerError,
    selectActiveVenueId,
    addDiaryFilesToQueue,
} from "app/appSlice";
import {
    addDiaryEntries,
    addTaskDiaryEntries,
} from "features/tasks/tasksSlice";
import { setStaff } from "./staffSlice";
import { appDispatch, appSelect } from "app/util";
import type { AppStartListening } from "app/listeners";

export const startStaffListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getStaff.matchFulfilled,
        effect: (action, listenerApi) => {
            // Don't set data if not logged in to a venue or the
            // tasks venue is not the active venue
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId || action.meta.arg.originalArgs !== venueId) return;

            const staff = action.payload;
            listenerApi.dispatch(setStaff({ venueId, data: staff }));
            listenerApi.dispatch(setServerError(["staff", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getStaff.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const status = action.payload?.status;
            const data = action.payload?.data;
            const datetime = new Date().toISOString();
            const error = {
                httpCode: Number(status),
                data,
                datetime,
            };
            listenerApi.dispatch(setServerError(["staff", error]));
        },
    });
};

export const startSetUserListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: appSlice.actions.setActiveUser,
        effect: (action, listenerApi) => {
            let pendingDiaryEntries = appSelect(selectPendingDiaries);
            if (pendingDiaryEntries && pendingDiaryEntries.length > 0) {
                const userId = action.payload;
                if (userId) {
                    const userDiaries = [];
                    for (let diary of pendingDiaryEntries) {
                        diary = {
                            ...diary,
                            userId,
                        };
                        userDiaries.push(diary);
                    }

                    appDispatch(addDiaryEntries(userDiaries));
                    appDispatch(addTaskDiaryEntries(userDiaries));
                    // Likely redundant but ensure pending diary gets deleted
                    appDispatch(setPendingDiaryEntry());

                    const pendingDiaryFiles = appSelect(
                        selectPendingDiaryFiles
                    );
                    if (pendingDiaryFiles) {
                        appDispatch(addDiaryFilesToQueue(pendingDiaryFiles));
                        appDispatch(setPendingDiaryFiles());
                    }

                    const activeFormDataSetter = appSelect(
                        getActiveFormDataSetter
                    );
                    const activeScreenSetter = appSelect(getActiveScreenSetter);

                    if (activeFormDataSetter)
                        appDispatch(activeFormDataSetter(void 0));
                    if (activeScreenSetter) appDispatch(activeScreenSetter({}));
                }
            }
        },
    });
};

export const startSetShowUserSelectListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: appSlice.actions.setShowUserSelect,
        effect: (action, listenerApi) => {
            // Hiding staff selector
            if (action.payload === false) {
                // Only need pending diary if setting the user
                appDispatch(setPendingDiaryEntry());
            }
        },
    });
};

const listeners = [
    startStaffListening,
    startSetUserListening,
    startSetShowUserSelectListening,
];
export default listeners;
