import type { UUID } from "types";
import type { ServerError } from "app/appSlice";
import { generateUniqueId } from "features/environment/util";

export type NetworkOperationType = "invite" | "profile";
export type NetworkOperationStatus =
    | "pending"
    | "active"
    | "success"
    | "failed";

export interface NetworkOperation<Data = {}> {
    id: UUID;
    type: NetworkOperationType;
    status: NetworkOperationStatus;
    error?: ServerError;
    data: Data;
}

export function createNetworkOperation<Data>(
    type: NetworkOperationType,
    data: Data
): NetworkOperation<Data> {
    return {
        id: generateUniqueId(),
        type,
        status: "pending",
        data,
    };
}

export abstract class NetworkOperationHandler {
    abstract type: NetworkOperationType;
    abstract processOperation(operation: NetworkOperation): void;
}
