import type { AppStartListening } from "app/listeners";
import { UpdatedProfileActionType } from "features/profile/useProfileForm";
import { setProfile } from "features/loginToken/loginTokenSlice";
import type { Profile } from "features/loginToken/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { appServiceApi } from "services/appService";
import { getServerError } from "services/appService";
import { createNetworkOperation } from "features/networkQueue/types";
import { setNetworkOperation } from "app/appSlice";
import {
    createCompletedOperation,
    createFailedOperation,
} from "features/networkQueue/NetworkQueue";
import { setServerError } from "app/appSlice";

export const startProfileListening = (startListening: AppStartListening) => {
    startListening({
        type: UpdatedProfileActionType,
        effect: (action, listenerApi) => {
            const profileAction = action as PayloadAction<Profile>;
            listenerApi.dispatch(setProfile(profileAction.payload));
            const operation = createNetworkOperation(
                "profile",
                profileAction.payload
            );
            listenerApi.dispatch(setNetworkOperation(operation));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.updateProfile.matchFulfilled,
        effect: (action, listenerApi) => {
            let args = action.meta.arg.originalArgs;
            console.log("Updated profile successfully");
            let completedAction = createCompletedOperation(args);
            listenerApi.dispatch(completedAction);
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.updateProfile.matchRejected,
        effect: (action, listenerApi) => {
            let args = action.meta.arg.originalArgs;
            console.log("Error updating profile: %o", action.payload);
            const error = getServerError(action);
            let failedAction = createFailedOperation(args, error);
            listenerApi.dispatch(failedAction);
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getProfile.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setProfile(action.payload));
            listenerApi.dispatch(setServerError(["profile", void 0]));
        },
    });
};

const listeners = [startProfileListening];
export default listeners;
