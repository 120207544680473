import { ReactNode } from "react";
import styles from "./Error.module.scss";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "features/tasks/form/Button";

interface Props {
    onConfirm?: () => void;
    title?: string;
    children?: ReactNode;
    confirmTitle?: string;
}

export default function Error(props: Props) {

    const reloadPlan = () => {
        window.location.reload();
    };

    return (
        <div className={styles.container}>
            <div className={styles.Error}>
                <h2 className={styles.heading}>
                    {props.title || "Error"}
                    {props.onConfirm && (
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={props.onConfirm}
                        >
                            <Xmark className={iconStyles.smallIcon} />
                        </div>
                    )}
                    {!props.onConfirm && (
                        <button onClick={reloadPlan}>
                            Reload
                        </button>
                    )}
                </h2>
                <div>
                    {props.children && (
                        <div className={styles.content}>{props.children}</div>
                    )}
                </div>
                <footer>
                    <div>
                        <div className={styles.content}>
                            If this error message persists, please contact Chomp
                            Support (support@chompfoodsafety.com) for assistance.
                        </div>
                    </div>
                    {props.onConfirm && (
                        <Button onClick={props.onConfirm} type="submit">
                            {props.confirmTitle || "OK"}
                        </Button>
                    )}
                </footer>
            </div>
        </div>
    );
}
