import type {
    FormData,
    FormComponentData,
    DataSources,
    ComponentPredicate,
    ComponentError,
    ComponentCalculation,
    Calculation,
    DataSource,
    ComponentPredicateDefinition,
} from "./types";
import type { TaskData, SensorReading } from "features/tasks/types";
import formClasses from "./formClasses";

interface ModelData {
    value: any;
    sensorReading?: SensorReading;
    error?: ComponentError;
    showPredicate?: string;
    label?: string;
}

function trim(value: any): typeof value {
    if (typeof value === "string") {
        value = value.trim();
    }

    return value;
}

export function testPredicates(
    data: Record<string, any>,
    dataSources: DataSources,
    predicates: ComponentPredicate[],
    calculations: Record<string, number | ComponentCalculation>,
    componentValue?: any
) {
    let passed = true;
    for (let predicate of predicates) {
        let value;
        if (predicate.model && predicate.model in dataSources) {
            const dataSourceName = predicate.model as DataSource;
            if (predicate.property === "length") {
                const dataSource = dataSources[dataSourceName];
                if (dataSource && typeof dataSource === "object") {
                    value = dataSource.length;
                }
            } else {
                value = dataSources[dataSourceName];
            }
        } else if (predicate.model && predicate.model in data) {
            value = data[predicate.model];
        } else if (predicate.model && predicate.model in calculations) {
            if (typeof calculations[predicate.model] === "number") {
                value = calculations[predicate.model];
            } else if (typeof calculations[predicate.model] === "object") {
                const calculation = calculations[
                    predicate.model
                ] as ComponentCalculation;
                let calcData: number[] = [];
                calculation.models.reduce((calcData, model) => {
                    if (model in data) {
                        calcData.push(Number(data[model]));
                    } else if (model === "_componentValue") {
                        calcData.push(Number(componentValue));
                    }
                    return calcData;
                }, calcData);
                value = performCalculation(
                    calculation.calculation,
                    calcData,
                    calculation.argument
                );
            }
        } else if (
            componentValue &&
            predicate.model &&
            predicate.model === "_componentValue"
        ) {
            value = componentValue;
        }
        let comparator = predicate.comparator;
        if (!comparator) comparator = "==";

        let predicateValue = predicate.value;
        if (
            typeof predicate.value === "string" &&
            predicate.value.charAt(0) === "$"
        ) {
            const valueName = predicate.value.substring(1);
            if (valueName in data) {
                predicateValue = data[valueName];
            } else if (valueName in calculations) {
                predicateValue = calculations[valueName];
            }
        }

        if (
            (value === void 0 ||
                (typeof value === "number" && !isFinite(value))) &&
            comparator !== "!="
        ) {
            passed = false;
        } else {
            // Assume if predicateValue is a number, value should be compared as a number
            if (isFinite(Number(String(predicateValue)))) value = Number(value);

            // Handle time comparisons
            const timeRegex = /[0-9]+:[0-9]+/;
            if (
                typeof value === "string" &&
                value.match(timeRegex) &&
                typeof predicateValue === "string" &&
                predicateValue.match(timeRegex)
            ) {
                value = Number(value.replace(":", "."));
                predicateValue = Number(predicateValue.replace(":", "."));
            }

            switch (comparator) {
                case "==":
                    passed = predicateValue === value;
                    break;
                case "!=":
                    passed = predicateValue !== value;
                    break;
                case ">":
                    passed = value > predicateValue;
                    break;
                case "<":
                    passed = value < predicateValue;
                    break;
            }
        }

        if (!passed) break;
    }

    return passed;
}

type ValidateFormResult = [
    enabledModels: Record<string, ModelData>,
    isValid: boolean,
    problems: Record<string, any>,
    required: Record<string, boolean>,
    ignore: Record<string, boolean>,
    invalid: Record<string, boolean>
];

function calculateOutlier(
    values: number[],
    minGap?: number
): number | undefined {
    let outlier;
    if (values.length < 4) return outlier;
    if (!minGap) minGap = 1;

    let sorted = [...values].sort((a, b) => a - b);
    let min = sorted[0];
    let max = sorted[sorted.length - 1];
    if (minGap && max - min < minGap) return outlier;
    let median = sorted[Math.floor(sorted.length / 2)];
    if (median - min > max - median) {
        outlier = min;
    } else {
        outlier = max;
    }

    return outlier;
}

export function performCalculation(
    calculation: Calculation,
    calcData: number[],
    argument?: number
) {
    let result;
    switch (calculation) {
        case "max":
            // find the maximum value in calcData
            result = Math.max(...calcData);
            break;
        case "min":
            // find the minimum value in calcData
            result = Math.min(...calcData);
            break;
        case "subtract":
            // find the absolute value off all calcData subtracted from each other
            if (calcData.length > 1) {
                result = Math.abs(calcData.reduce((a, b) => a - b));
            }
            break;
        case "outlier":
            // find the value this is not within allowed range values (?)
            if (!argument && argument !== 0) argument = 1;
            result = calculateOutlier(calcData, argument);
            break;
    }

    return result;
}

export function getPredicateConditions(
    predicates: ComponentPredicate[] | ComponentPredicateDefinition
): ComponentPredicate[] {
    let conditions: ComponentPredicate[] = [];
    if (Array.isArray(predicates)) {
        conditions = predicates;
    } else {
        conditions = predicates.conditions;
    }

    return conditions;
}

export default function validateForm(
    formData: FormData,
    dataSources: DataSources,
    task: TaskData
): ValidateFormResult {
    let data = formData.data;
    let enabledModels: Record<string, ModelData> = {};
    let problems: Record<string, any> = {};
    let required: Record<string, boolean> = {};
    let ignore: Record<string, boolean> = {};
    let invalid: Record<string, boolean> = {};
    let isValid = true;
    const showErrors = formData.showErrors;
    let calculations: Record<string, any> = {};
    if (formData.formView.calculations) {
        for (let calculationId in formData.formView.calculations) {
            let calculation = formData.formView.calculations[calculationId];
            if (calculation.models.includes("_componentValue")) {
                calculations[calculationId] = calculation;
            } else {
                const calcData = calculation.models
                    .map((model) => {
                        if (model in data) {
                            return Number(data[model]);
                        } else if (model in calculations) {
                            return Number(calculations[model]);
                        } else {
                            return Number.NaN;
                        }
                    })
                    .filter((value) => !Number.isNaN(value));
                let argument = calculation.argument;
                if (calcData && calcData.length > 0) {
                    calculations[calculationId] = performCalculation(
                        calculation.calculation,
                        calcData,
                        argument
                    );
                }
            }
        }
    }

    if (formData.formView.extraData) {
        for (let extraData of formData.formView.extraData) {
            let passed = true;
            if (extraData.predicates) {
                passed = false;
                for (let predicateId of extraData.predicates) {
                    if (
                        formData.formView.predicates &&
                        predicateId in formData.formView.predicates
                    ) {
                        const predicates =
                            formData.formView.predicates[predicateId];
                        const conditions = getPredicateConditions(predicates);
                        if (
                            testPredicates(
                                data,
                                dataSources,
                                conditions,
                                calculations,
                                extraData.value
                            )
                        ) {
                            passed = true;
                            break;
                        }
                    }
                }
            }

            if (passed) {
                let value = extraData.value;
                if (
                    extraData.value &&
                    typeof extraData.value === "string" &&
                    extraData.value.charAt(0) === "$"
                ) {
                    const valueName = extraData.value.substring(1);
                    if (valueName in data) {
                        value = data[valueName];
                    } else if (valueName in dataSources) {
                        value = dataSources[valueName as DataSource];
                    }
                }

                enabledModels[extraData.model] = {
                    value,
                };
            }
        }
    }

    let components = formData.formView.components.reduce(
        (prev: FormComponentData[], current) => {
            prev.push(current);
            if (current.components) {
                prev = prev.concat(current.components);
            }

            return prev;
        },
        []
    );

    for (let formComponentData of components) {
        let model = formComponentData.model || "";
        let value = data[model];
        let showPredicate;
        if (
            formComponentData.showPredicates &&
            formComponentData.showPredicates.length > 0
        ) {
            let passed = false;
            for (let predicateId of formComponentData.showPredicates) {
                if (
                    formData.formView.predicates &&
                    predicateId in formData.formView.predicates
                ) {
                    const predicates =
                        formData.formView.predicates[predicateId];
                    const conditions = getPredicateConditions(predicates);
                    if (
                        testPredicates(
                            data,
                            dataSources,
                            conditions,
                            calculations,
                            value
                        )
                    ) {
                        passed = true;
                        showPredicate = predicateId;
                        break;
                    }
                }
            }

            if (!passed) continue;
        }

        if (formComponentData.problemPredicates) {
            for (let predicateId of formComponentData.problemPredicates) {
                if (
                    formData.formView.predicates &&
                    predicateId in formData.formView.predicates
                ) {
                    const predicates =
                        formData.formView.predicates[predicateId];
                    const conditions = getPredicateConditions(predicates);
                    const nonModelConditions = conditions.filter(
                        (condition) =>
                            condition.model !== formComponentData.model
                    );

                    // Only check for a problem if the component has a value
                    // or if its problem refers to another component
                    // e.g. a TextEntry that gets shown when a particular TabOption
                    // is selected
                    if (value === void 0 && nonModelConditions.length === 0) {
                        continue;
                    }

                    if (
                        testPredicates(
                            data,
                            dataSources,
                            conditions,
                            calculations,
                            value
                        )
                    ) {
                        // Only first problem noted
                        problems[model] = true;
                        // if (formComponentData.problemError) {
                        //     console.log(formComponentData.label + " problem");
                        //     isValid = false;
                        // }
                        break;
                    }
                }
            }
        }

        if (formComponentData.invalidPredicates) {
            for (let predicateId of formComponentData.invalidPredicates) {
                if (
                    formData.formView.predicates &&
                    predicateId in formData.formView.predicates
                ) {
                    const predicates =
                        formData.formView.predicates[predicateId];
                    const conditions = getPredicateConditions(predicates);
                    const nonModelConditions = conditions.filter(
                        (condition) =>
                            condition.model !== formComponentData.model
                    );

                    // Only check for invalid if the component has a value
                    if (value === void 0 && nonModelConditions.length === 0) {
                        continue;
                    }

                    if (
                        testPredicates(
                            data,
                            dataSources,
                            conditions,
                            calculations,
                            value
                        )
                    ) {
                        invalid[model] = true;
                        isValid = false;
                        break;
                    }
                }
            }
        }

        let isRequired = false;
        if (formComponentData.type in formClasses && formComponentData.model) {
            enabledModels[formComponentData.model] = {
                value,
                showPredicate,
            };
            if (
                formComponentData.dataSourceIdModel &&
                formComponentData.dataSourceIdModel in data
            ) {
                enabledModels[formComponentData.dataSourceIdModel] = {
                    value: data[formComponentData.dataSourceIdModel],
                };
            }

            if (formComponentData.useSensor && task.source === "sensor") {
                enabledModels[formComponentData.model]["sensorReading"] =
                    task.reading;
            }

            let error: ComponentError | undefined;
            const requiredMessage =
                formComponentData.requiredMessage || "This field is required";
            if (formComponentData.required !== false) {
                if (!trim(value) && value !== false && value !== 0) {
                    isValid = false;
                    isRequired = true;
                    if (showErrors) {
                        error = {
                            type: "required",
                            message: requiredMessage,
                        };
                        enabledModels[formComponentData.model]["error"] = error;
                    } else {
                        delete enabledModels[formComponentData.model]["error"];
                    }
                }
            }

            // If start is set and it's a number, assume value should be a number
            if (value !== void 0 && Number.isFinite(formComponentData.start)) {
                if (!Number.isFinite(Number(value))) {
                    isValid = false;
                    enabledModels[formComponentData.model]["error"] = {
                        type: "numeric",
                        message: "This should be a number",
                    };
                }
            } else if (formComponentData.requiredPredicates) {
                for (let predicateId of formComponentData.requiredPredicates) {
                    if (
                        formData.formView.predicates &&
                        predicateId in formData.formView.predicates
                    ) {
                        const predicates =
                            formData.formView.predicates[predicateId];
                        const conditions = getPredicateConditions(predicates);
                        let passed = testPredicates(
                            data,
                            dataSources,
                            conditions,
                            calculations,
                            value
                        );
                        if (passed) isRequired = true;
                        if (
                            passed &&
                            !value &&
                            value !== false &&
                            value !== 0
                        ) {
                            isValid = false;
                            if (showErrors) {
                                error = {
                                    type: "required",
                                    message: requiredMessage,
                                };
                                enabledModels[formComponentData.model][
                                    "error"
                                ] = error;
                            } else {
                                delete enabledModels[formComponentData.model][
                                    "error"
                                ];
                            }
                            break;
                        }
                    }
                }
            }
        }
        required[model] = isRequired;

        if (formComponentData.labelAlternatives && model in enabledModels) {
            for (let alternative of formComponentData.labelAlternatives) {
                let passed = false;
                for (let predicateId of alternative.predicates) {
                    if (
                        formData.formView.predicates &&
                        predicateId in formData.formView.predicates
                    ) {
                        const predicates =
                            formData.formView.predicates[predicateId];
                        const conditions = getPredicateConditions(predicates);
                        if (
                            testPredicates(
                                data,
                                dataSources,
                                conditions,
                                calculations,
                                value
                            )
                        ) {
                            passed = true;
                            break;
                        }
                    }
                }

                if (passed) {
                    enabledModels[model]["label"] = alternative.label;
                    break;
                }
            }
        }

        if (formComponentData.options) {
            for (let option of formComponentData.options) {
                if (option.value === value && option.ignore) {
                    ignore[model] = true;
                    break;
                }
            }
        }
    }

    return [enabledModels, isValid, problems, required, ignore, invalid];
}
