import { Produce } from "glossy";
import { getStore } from "app/store";
import { selectActiveVenueId } from "app/appSlice";
import { appServiceApi } from "services/appService";
import { version } from "VERSION";

export default function sendDebugData(
    message: string,
    data: object,
    severity?: string
) {
    const state = getStore().getState();
    const dispatch = getStore().dispatch;
    const venueId = selectActiveVenueId(state);
    if (!venueId) {
        console.error("Can't send debug data: No active venue");
        return;
    }

    const producer = new Produce({
        facility: "local0",
        severity: "debug",
        appName: "chef",
    });

    const debugData = {
        ...state.app,
        diaryFileQueue: `${state.app.diaryFileQueue?.length} files`,
        pendingDiaryFiles: `${state.app.pendingDiaryFiles?.length} files`,
        deviceId: state.environment.uniqueId,
        profile: state.loginToken.profile,
        userAgent: navigator?.userAgent,
        version,
    };

    const syslog = producer.produce({
        severity: severity || "debug",
        host: window.location.hostname,
        date: new Date(),
        message: message,
        structuredData: {
            "kaitoa@data": {
                app: JSON.stringify(debugData),
                data: JSON.stringify(data),
            },
        },
    });

    dispatch(
        appServiceApi.endpoints.sendDebugData.initiate({
            venueId,
            data: syslog || "",
        })
    );
}
