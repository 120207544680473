import React, { useCallback } from "react";
import styles from "./VenueClosed.module.scss";
import { ChefClosed } from "icons/icons";

export default function VenueClosed() {
    return (
        <div className={styles.VenueClosed}>
            <div className={styles.icon}>
                <ChefClosed />
            </div>
            <h1>Kitchen Closed</h1>
            <p>The kitchen is scheduled to be closed today.</p>
        </div>
    );
}
