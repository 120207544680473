import styles from "./ProfileForm.module.scss";
import Form from "react-bootstrap/Form";
import Button from "features/tasks/form/Button";
import formStyles from "app/form.module.scss";
import useProfileForm from "./useProfileForm";
import FormErrorMessage from "app/FormError";
import { getCountryList, getLanguageList } from "app/util";

interface Props {
    onDone: () => void;
}

let _countryOptions: JSX.Element[] | undefined = void 0;
function getCountryOptions(): JSX.Element[] {
    if (_countryOptions) return _countryOptions;
    const allCountries = getCountryList(navigator.language);

    const mainCountries = [
        {
            code: "NZ",
            name:
                allCountries.find((c) => c.code === "NZ")?.name ||
                "New Zealand",
        },
        {
            code: "AU",
            name:
                allCountries.find((c) => c.code === "AU")?.name || "Australia",
        },
    ];
    const countryList = mainCountries.concat(allCountries);
    const options = countryList.map((country, index) => (
        <option key={country.code + index} value={country.code}>
            {country.name}
        </option>
    ));

    _countryOptions = options;

    return options;
}

let _langOptions: JSX.Element[] | undefined = void 0;
function getLanguageOptions(): JSX.Element[] {
    if (_langOptions) return _langOptions;
    const allLanguages = getLanguageList(navigator.language);
    const options = allLanguages.map((lang, index) => (
        <option key={lang.code + index} value={lang.code}>
            {lang.name}
        </option>
    ));

    _langOptions = options;

    return options;
}

export default function ProfileForm(props: Props) {
    const { save, register, errors, maxDob, profile, defaultLanguage } =
        useProfileForm();
    const onCancel = () => {
        props.onDone();
    };
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        save();
        props.onDone();
    };

    return (
        <div className={styles.ProfileForm}>
            <h1>Edit Profile</h1>
            <Form onSubmit={onSubmit}>
                <Form.Group className={formStyles.formGroup}>
                    <Form.Label className={formStyles.required}>
                        First Name
                    </Form.Label>
                    <Form.Control
                        defaultValue={profile?.firstName}
                        {...register("firstName", { required: true })}
                    />
                    <FormErrorMessage error={errors.firstName} />
                </Form.Group>
                <Form.Group className={formStyles.formGroup}>
                    <Form.Label className={formStyles.required}>
                        Last Name
                    </Form.Label>
                    <Form.Control
                        defaultValue={profile?.lastName}
                        {...register("lastName", { required: true })}
                    />
                    <FormErrorMessage error={errors.lastName} />
                </Form.Group>

                <Form.Group className={formStyles.formGroup}>
                    <label className={formStyles.required}>Email</label>
                    <Form.Control
                        defaultValue={profile?.email}
                        {...register("email", { required: true })}
                    />
                    <FormErrorMessage error={errors.email} />
                </Form.Group>

                <Form.Group className={formStyles.formGroup}>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                        defaultValue={profile?.mobile}
                        type="tel"
                        minLength={9}
                        pattern="[0-9+ \(\)\-]+"
                        {...register("mobile")}
                    />
                    <FormErrorMessage error={errors.mobile} />
                </Form.Group>

                <Form.Group className={formStyles.formGroup}>
                    <Form.Label>Date of birth</Form.Label>
                    <Form.Control
                        defaultValue={profile?.dob}
                        type="date"
                        max={maxDob}
                        {...register("dob")}
                    />
                    <FormErrorMessage error={errors.dob} />
                </Form.Group>

                <Form.Group className={formStyles.formGroup}>
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                        defaultValue={profile?.country}
                        {...register("country")}
                    >
                        {getCountryOptions()}
                    </Form.Select>
                    <FormErrorMessage error={errors.country} />
                </Form.Group>

                <Form.Group className={formStyles.formGroup}>
                    <Form.Label>Language</Form.Label>
                    <Form.Select
                        defaultValue={profile?.language || defaultLanguage}
                        {...register("language")}
                    >
                        {getLanguageOptions()}
                    </Form.Select>
                    <FormErrorMessage error={errors.language} />
                </Form.Group>

                <div className={styles.controls}>
                    <Button onClick={onCancel} className={styles.cancel}>
                        Cancel
                    </Button>
                    <Button type="submit" className={styles.submit}>
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
}
