import type { Profile } from "features/loginToken/types";
import styles from "./ProfileScreen.module.scss";
import { User } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "features/tasks/form/Button";
import { getCountryList, getLanguageList } from "app/util";

interface Props {
    profile: Profile;
    onEdit: () => void;
    onChangePassword: () => void;
}

const _countryList = getCountryList();
function getCountryName(code: string): string {
    const country = _countryList.find((c) => c.code === code);
    return country ? country.name : code;
}
const _languageList = getLanguageList();
function getLanguageName(code: string): string {
    const lang = _languageList.find((c) => c.code === code);
    return lang ? lang.name : code;
}

export default function ProfileView(props: Props) {
    return (
        <div className={styles.ProfileView}>
            <h1>
                <div className={styles.userIcon}>
                    <User className={iconStyles.largeIcon} />
                </div>
                {props.profile.firstName}
                {props.profile.lastName && " " + props.profile.lastName}
            </h1>
            <div className={styles.details}>
                <h3>Email</h3>
                <p className={styles.value}>{props.profile.email}</p>
                {props.profile.mobile && (
                    <>
                        <h3>Mobile</h3>
                        <p className={styles.value}>{props.profile.mobile}</p>
                    </>
                )}
                {props.profile.dob && (
                    <>
                        <h3>Date of birth</h3>
                        <p className={styles.value}>{props.profile.dob}</p>
                    </>
                )}
                {props.profile.country && (
                    <>
                        <h3>Country</h3>
                        <p className={styles.value}>
                            {getCountryName(props.profile.country)}
                        </p>
                    </>
                )}
                {props.profile.language && (
                    <>
                        <h3>Language</h3>
                        <p className={styles.value}>
                            {getLanguageName(props.profile.language)}
                        </p>
                    </>
                )}
            </div>
            <div className={styles.controls}>
                <div>
                    <Button onClick={props.onEdit}>Edit</Button>
                    <Button onClick={props.onChangePassword}>
                        Change Password
                    </Button>
                </div>
            </div>
        </div>
    );
}
