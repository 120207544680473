import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "features/tasks/form/Button";
import useSetPasswordScreen from "./useSetPasswordScreen";
import loaderStyles from "app/Loader.module.css";
import styles from "./SetPasswordScreen.module.scss";
import type { FormData } from "./useSetPasswordScreen";
import FormErrorMessage from "app/FormError";
import formStyles from "app/form.module.scss";
import { Eye, EyeSlash } from "icons/icons";
import iconStyles from "icons/Icon.module.css";

export default function SetPasswordScreen() {
    const {
        setPasswordState,
        errors,
        passwordErrorMessage,
        resetPasswordCredential,
        generatedPassword,
        isLoggedIn,
        showCurrent,
        showNew,
        showConfirm,
        profile,
        handleSubmit,
        register,
        setPassword,
        validateConfirm,
        toggleCurrent,
        toggleNew,
        toggleConfirm,
    } = useSetPasswordScreen();
    const onSubmit = (data: FormData) => {
        setPassword(data);
    };
    return (
        <div className={styles.SetPasswordScreen}>
            {!isLoggedIn && (
                <div className={styles.logoContainer}>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </div>
            )}
            <p>
                {resetPasswordCredential &&
                    "To complete your password reset, please set your new password."}
                {generatedPassword &&
                    "To complete your registration, please set a new password."}
            </p>
            <p>
                Please enter your new password and confirmation so we can verify
                you typed it in correctly.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {!resetPasswordCredential && !generatedPassword && (
                    <Form.Group className={styles.group}>
                        <Form.Label>Current password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showCurrent ? "text" : "password"}
                                defaultValue={""}
                                id="currentPassword"
                                {...register("current", { required: true })}
                            />
                            <InputGroup.Text onClick={toggleCurrent}>
                                {!showCurrent && (
                                    <Eye className={iconStyles.largeIcon} />
                                )}
                                {showCurrent && (
                                    <EyeSlash
                                        className={iconStyles.largeIcon}
                                    />
                                )}
                            </InputGroup.Text>
                        </InputGroup>
                        <FormErrorMessage error={errors.current} />
                    </Form.Group>
                )}

                <Form.Group className={styles.group}>
                    <Form.Label>New password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showNew ? "text" : "password"}
                            defaultValue={""}
                            id="newPassword"
                            autoComplete="off"
                            minLength={9}
                            {...register("new", { required: true })}
                        />
                        <InputGroup.Text onClick={toggleNew}>
                            {!showNew && (
                                <Eye className={iconStyles.largeIcon} />
                            )}
                            {showNew && (
                                <EyeSlash className={iconStyles.largeIcon} />
                            )}
                        </InputGroup.Text>
                    </InputGroup>
                    <FormErrorMessage error={errors.new} />
                </Form.Group>

                <Form.Group className={styles.group}>
                    <Form.Label>Confirm new password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showConfirm ? "text" : "password"}
                            defaultValue={""}
                            id="confirmPassword"
                            autoComplete="off"
                            minLength={9}
                            {...register("confirm", {
                                required: true,
                                validate: validateConfirm,
                            })}
                        />
                        <InputGroup.Text onClick={toggleConfirm}>
                            {!showConfirm && (
                                <Eye className={iconStyles.largeIcon} />
                            )}
                            {showConfirm && (
                                <EyeSlash className={iconStyles.largeIcon} />
                            )}
                        </InputGroup.Text>
                    </InputGroup>
                    <FormErrorMessage error={errors.confirm} />
                </Form.Group>

                {!setPasswordState?.isLoading && (
                    <>
                        <Button type="submit">Set Password</Button>
                    </>
                )}
                {setPasswordState?.isLoading && (
                    <div className={styles.loginLoader}>
                        <div className={loaderStyles.Loader}>
                            <img
                                src="/ChompSpinner.svg"
                                alt="Setting password..."
                            />
                        </div>
                    </div>
                )}
                {passwordErrorMessage && (
                    <p className={formStyles.error}>{passwordErrorMessage}</p>
                )}
            </Form>
        </div>
    );
}
