import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppStartListening } from "app/listeners";
import { setUrlPath, setUrlParams } from "app/appSlice";
import routes, { RouteName } from "app/routes";
import { parse } from "regexparam";
import { getQueryParams } from "./util";

export const ShowRouteActionType = "showRoute";
export function showRoute(route: RouteName): PayloadAction<RouteName> {
    return {
        type: ShowRouteActionType,
        payload: route,
    };
}

type RegexparamResult = {
    keys: string[];
    pattern: RegExp;
};

function getUrlParams(path: string, result: RegexparamResult) {
    let i = 0;
    let out: Record<string, any> = {};
    let matches = result.pattern.exec(path);
    while (matches && i < result.keys.length) {
        out[result.keys[i]] = matches[++i] || null;
    }
    return out;
}

const _urlCache: Record<string, RegexparamResult> = {};
function getUrlComponents(routeUrl: string): RegexparamResult {
    let routeComponents;
    if (_urlCache[routeUrl]) {
        routeComponents = _urlCache[routeUrl];
    } else {
        routeComponents = parse(routeUrl);
        _urlCache[routeUrl] = routeComponents;
    }

    return routeComponents;
}

export const startRouteListening = (startListening: AppStartListening) => {
    startListening({
        type: ShowRouteActionType,
        effect: (action, listenerApi) => {
            let payloadAction = action as PayloadAction<RouteName>;
            const route = routes[payloadAction.payload];
            listenerApi.dispatch(
                setUrlPath({ url: route.url, pushState: true })
            );
        },
    });

    startListening({
        actionCreator: setUrlPath,
        effect: (action, listenerApi) => {
            if (
                action.payload.pushState !== false &&
                action.payload.url !== window.location.pathname
            ) {
                window.history.pushState({}, "", action.payload.url);
            }
            const queryParams = getQueryParams();
            listenerApi.dispatch(setUrlParams(queryParams));
            if (action.payload.processed) return;
            // Remove any trailing slash and query args
            const url = action.payload.url.replace(/(.\/$|\/?\?.*)/, "");
            for (let route of Object.values(routes)) {
                const routeComponents = getUrlComponents(route.url);
                if (route.url === url || routeComponents.pattern.test(url)) {
                    const params = getUrlParams(url, routeComponents);
                    if (route.setup) {
                        route.setup(
                            listenerApi.dispatch,
                            listenerApi.getState(),
                            params
                        );
                    }
                    return;
                }
            }
        },
    });
};

const listeners = [startRouteListening];
export default listeners;
