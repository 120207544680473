import Alert from "react-bootstrap/Alert";
import { isTestServer } from "app/util";
import styles from "./LoginError.module.scss";
import type { LoginError } from "./loginTokenSlice";

interface Props {
    loginError?: LoginError;
    tokenSource?: string;
}

const CHEF_SERVER = "chef.chomp.nz";
const CHEF_TEST_SERVER = "chef.test.chomp.nz";

export default function LoginErrorView(props: Props) {
    let errorMessage = <>{props.loginError?.error}</>;
    if (props.tokenSource === "migrate") {
        const server = isTestServer() ? CHEF_TEST_SERVER : CHEF_SERVER;
        errorMessage = (
            <>
                <p className={styles.container}>
                    There has been an error migrating this device: <br />
                    <span className={styles.loginError}>
                        {props.loginError?.error}
                    </span>
                </p>

                <p className={styles.container}>
                    Please try migrating again from{" "}
                    <a href={`https://${server}`}>
                        your old version of the Chomp app
                    </a>{" "}
                    if you have a QR code, try scanning your QR code using the
                    button below.
                </p>

                <p className={styles.container}>
                    If problems persist, please get in contact with{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.chompfoodsafety.com/customer-support"
                    >
                        our helpful support team
                    </a>
                    .
                </p>
            </>
        );
    } else if (props.tokenSource === "qrcode") {
        errorMessage = (
            <>
                <p className={styles.container}>
                    There has been an error logging in via QR Code scan: <br />
                    <span className={styles.loginError}>
                        {props.loginError?.error}
                    </span>
                </p>

                <p className={styles.container}>
                    Please try again by scanning your QR code using the button
                    below.
                </p>

                <p className={styles.container}>
                    If problems persist, please get in contact with{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.chompfoodsafety.com/customer-support"
                    >
                        our helpful support team
                    </a>
                    .
                </p>
            </>
        );
    } else if (props.loginError?.type === "user") {
        errorMessage = (
            <>
                <p>User: {props.loginError?.error}</p>
            </>
        );
    } else {
        errorMessage = (
            <>
                <p className={styles.container}>
                    There has been an error logging into the app: <br />
                    <span className={styles.loginError}>
                        {props.loginError?.error}
                    </span>
                </p>

                <p className={styles.container}>
                    Please try clicking the link again, or if you have a QR
                    code, try scanning your QR code using the button below.
                </p>

                <p className={styles.container}>
                    If problems persist, please get in contact with{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.chompfoodsafety.com/customer-support"
                    >
                        our helpful support team here
                    </a>
                    .
                </p>
            </>
        );
    }

    let loginErrorComponent = <Alert variant="warning">{errorMessage}</Alert>;

    return loginErrorComponent;
}
