import Modal from "app/Modal";
import styles from "./ImageViewer.module.scss";
import CloseButton from "features/shared/CloseButton";

type Props = {
    onDismiss: () => void;
    src: string;
};

export default function ImageViewer(props: Props) {
    return (
        <Modal dismiss={props.onDismiss}>
            <div className={styles.ImageViewer}>
                <img src={props.src} alt="" />
                <CloseButton
                    onClick={props.onDismiss}
                    className={styles.close}
                />
            </div>
        </Modal>
    );
}
