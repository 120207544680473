import { QueryStatus } from "@reduxjs/toolkit/query";

export interface QueryState {
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    error?: string;
}

export const loadedQueryState: QueryState = {
    isLoading: false,
    isFetching: false,
    isSuccess: true,
};

export interface RTKQueryState {
    status: QueryStatus;
    data?: any;
    error?: any;
}

// Cache QueryState objects to avoid redux re-renders
// when returned by useAppSelector etc.
let _getQueryStateCache: { [key: string]: QueryState } = {};
export const getQueryState = (state: RTKQueryState): QueryState => {
    let error;
    if (state.error && "error" in state.error) {
        error = state.error;
    }
    const queryState = {
        isLoading: state.status === QueryStatus.pending,
        isFetching: state.data && state.status === QueryStatus.pending,
        isSuccess: !!state.data,
        error: error,
    };
    const queryStateString = JSON.stringify(queryState);
    const cachedQueryState = _getQueryStateCache[queryStateString];
    if (cachedQueryState) {
        return cachedQueryState;
    }
    _getQueryStateCache[queryStateString] = queryState;

    return queryState;
};
