import React from "react";
import classnames from "classnames";
import { DateTime } from "luxon";
import { DiaryEntry } from "features/tasks/types";
import { Success, Warning } from "icons/icons";
import styles from "./NetworkStatus.module.css";

import iconStyles from "icons/Icon.module.css";

interface Props {
    offlineStart?: Date;
    queuedDiaryEntries: DiaryEntry[];
}

function formatDate(date: Date): string {
    const now = DateTime.now();
    const datetime = DateTime.fromJSDate(date);
    let formattedDatetime;
    // Did we go offline today?
    if (datetime.hasSame(now, "day")) {
        formattedDatetime = datetime.toFormat("t");
    } else {
        formattedDatetime = datetime.toFormat("d MMMM");
    }

    return formattedDatetime;
}

export default function NetworkStatus(props: Props) {
    let networkStatus;
    let classes = [styles.NetworkStatus];
    if (props.offlineStart) {
        classes.push(styles.offline);
        networkStatus = (
            <>
                <Warning
                    className={classnames(
                        iconStyles.smallIcon,
                        iconStyles.warning
                    )}
                />
                <div>
                    <h3>Offline since {formatDate(props.offlineStart)}</h3>
                    {props.queuedDiaryEntries.length > 0 && (
                        <p>
                            {props.queuedDiaryEntries.length} tasks waiting to
                            upload
                        </p>
                    )}
                </div>
            </>
        );
    } else {
        classes.push(styles.online);

        networkStatus = (
            <>
                <Success
                    className={classnames(
                        iconStyles.smallIcon,
                        iconStyles.success
                    )}
                />
                <h3>Online</h3>
            </>
        );
    }

    return <div className={classnames(...classes)}>{networkStatus}</div>;
}
