import React from "react";
import styles from "./Sidebar.module.scss";
import classnames from "classnames";

interface Props {
    isActive?: boolean;
    icon: JSX.Element; // SVG icon
    lable: string;
    onClick: () => void;
}

export default function SidebarButton(props: Props) {
    let classes = [styles.button];
    if (props.isActive) classes.push(styles.activeButton);

    return (
        <div className={classnames(...classes)} onClick={props.onClick}>
            {props.icon}
            {props.lable}
        </div>
    );
}
