import React, { useLayoutEffect } from "react";
import classnames from "classnames";
import type { TaskData } from "./types";
import styles from "./GroupTemperatureList.module.css";
import taskOverlayStyles from "./TasksOverlay.module.scss";
import TasksOverlay from "./TasksOverlay";
import { groupName, TaskObjectsFunction, getUrgency } from "./functions";
import NumberSlider from "./form/NumberSlider";
import TextEntry from "./form/TextEntry";
import useGroupTemperatureList from "./useGroupTemperatureList";
import type { FormComponentData, FormView } from "./form/types";
import { getDataSources } from "./form/TaskForm";
import validateForm from "./form/validateForm";
import { TaskUrgencies } from "./types";

export interface Props {
    group: TaskData[];
    formView: FormView;
    getTaskObjects: TaskObjectsFunction;
}

function getKey(taskId: number): string {
    return `row_${taskId}`;
}

function scrollToElement(elementId: string) {
    const scrollingElement = document.getElementsByClassName(
        taskOverlayStyles.TasksOverlay
    )[0];
    const listElement = document.getElementsByClassName(styles.list)[0];
    const scrollToElement = document.getElementById(elementId);
    if (
        scrollingElement instanceof HTMLElement &&
        listElement instanceof HTMLElement &&
        scrollToElement instanceof HTMLElement
    ) {
        const listTop = listElement.offsetTop;
        const targetTop = scrollToElement.offsetTop - listTop;
        scrollingElement.scroll({
            top: targetTop,
            left: 0,
            behavior: "auto",
        });
    }
}

export default function GroupTemperaturelist(props: Props) {
    const {
        temperatureListStatus,
        showErrors,
        activeTaskId,
        activeTaskValue,
        onSet,
        onError,
        onSubmit,
        onClose,
    } = useGroupTemperatureList(props.group);
    let items = [];
    let sliderData: FormComponentData | undefined;
    let textEntryData: FormComponentData | undefined;
    let activeKey = activeTaskId ? getKey(activeTaskId) : void 0;

    // Use activeTaskValue to force an element to re-scroll
    // into place after changing a value, even if activeKey
    // is the same.
    useLayoutEffect(() => {
        if (activeKey) {
            scrollToElement(activeKey);
        }
    }, [activeKey, activeTaskValue]);
    for (let data of props.formView.components) {
        if (data.type === "NumberSlider") {
            sliderData = data;
        } else if (data.type === "TextEntry") {
            textEntryData = data;
        }
    }

    if (!sliderData || !textEntryData) return <div />;

    let valid = true;
    for (let task of props.group) {
        let temperatureData = temperatureListStatus[task.id] ?? void 0;
        let data: Record<string, any> = temperatureData ?? {};
        let formData = {
            taskId: task.id,
            formView: props.formView,
            data,
            showErrors,
            files: {},
        };
        const dataSources = getDataSources(task);
        const hasSensor = task.source === "sensor";

        const [enabledModels, isValid, problems, _required] = validateForm(
            formData,
            dataSources,
            task
        );
        const sliderDataModel = sliderData.model || "";
        const value = enabledModels[sliderDataModel].value;
        if (value && !isValid) valid = false;
        const problem = problems[sliderDataModel];
        const error = value ? enabledModels[sliderDataModel].error : void 0;
        const urgency = getUrgency(task);

        let classes = [styles.item];
        classes.push(urgency === TaskUrgencies.URGENT ? styles.overdue : "");

        const onChange = (model: string, value: number) => {
            onSet(task.id, model, value);
        };

        const textEntryLable = textEntryData.label;
        const textEntryDataModel: string = textEntryData.model || "";
        const textEntryDescription = textEntryData.description;

        const key = getKey(task.id);
        items.push(
            <li className={classnames(...classes)} key={key} id={key}>
                <NumberSlider
                    name={task.name}
                    label={task.name}
                    model={sliderDataModel}
                    value={data[sliderDataModel]}
                    hasSensor={hasSensor}
                    sensorReading={task.reading}
                    start={sliderData.start}
                    end={sliderData.end}
                    increment={sliderData.increment}
                    problem={problem}
                    default={sliderData.default}
                    dataSources={dataSources}
                    onChange={onChange}
                    error={error}
                />
                {textEntryDataModel in enabledModels && (
                    <TextEntry
                        name={task.name}
                        label={textEntryLable}
                        model={textEntryDataModel}
                        value={data[textEntryDataModel]}
                        description={textEntryDescription}
                        error={
                            (showErrors &&
                                enabledModels[textEntryDataModel].error) ||
                            void 0
                        }
                        onChange={onChange}
                        dataSources={dataSources}
                    />
                )}
                {urgency === TaskUrgencies.URGENT && (
                    <span className={styles.statusContent}>overdue</span>
                )}
            </li>
        );
    }

    const onDone = () => {
        if (!valid) {
            onError();
            return;
        }

        onSubmit();
    };

    let title = groupName(props.group, props.getTaskObjects);

    return (
        <TasksOverlay title={title} onDone={onDone} onClose={onClose}>
            <ul className={styles.list}>{items}</ul>
        </TasksOverlay>
    );
}
