import React, { ChangeEvent } from "react";
import classnames from "classnames";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./TextEntry.module.css";
import { marked } from "marked";

interface Props extends ComponentProps {}

export default function TextEntry(props: Props) {
    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }
    let classNames = [];
    if (props.problem) classNames.push(styles.problem);
    if (props.error) classNames.push(formStyles.errorTextEntry);
    return (
        <div className={styles.TextEntry}>
            <div className={styles.heading}>
                <p className={formStyles.label}>{props.label}</p>
                {error}
            </div>

            <textarea
                name={props.model}
                value={props.value}
                rows={2}
                placeholder={props.required ? "Required" : ""}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    props.onChange(props.model, e.target.value);
                }}
                className={classnames(...classNames)}
            />
            {props.description && (
                <p
                    className={formStyles.description}
                    dangerouslySetInnerHTML={{
                        __html: marked.parse(props.description),
                    }}
                />
            )}
        </div>
    );
}
