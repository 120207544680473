import { appServiceApi } from "services/appService";
import { selectBearerToken } from "features/loginToken/loginTokenSlice";
import { getStore } from "app/store";

export default function refreshToken() {
    const token = selectBearerToken(getStore().getState());
    console.log("Called refreshToken, current token: %o", token);
    if (token) {
        getStore().dispatch(
            appServiceApi.endpoints.refreshToken.initiate(token, {
                forceRefetch: true,
            })
        );
    }
}
