import React from "react";
import styles from "./Button.module.css";
import classnames from "classnames";

interface Props {
    className?: string;
    children: string | React.ReactElement;
    onClick?: () => void;
    type?: "button" | "reset" | "submit";
    icon?: React.ReactElement;
    disabled?: boolean;
}

export default function Button(props: Props) {
    let classes = [styles.Button];
    if (props.className) classes.push(props.className);
    if (props.icon) classes.push(styles.iconButton);
    const disabled = props.disabled || false;
    return (
        <button
            className={classnames(...classes)}
            onClick={props.onClick}
            type={props.type || "button"}
            disabled={disabled}
        >
            {props.icon && <div className={styles.icon}>{props.icon}</div>}
            {props.children}
        </button>
    );
}
