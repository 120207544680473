import { PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "app/hooks";
import { useForm, UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import { isValidEmail } from "app/util";
import { QueryState, getQueryState } from "services/util";
import { appServiceApi } from "services/appService";

interface PasswordResetData {
    email: string;
    emailIsValid: boolean;
    passwordResetState: QueryState | undefined;
    errors: FieldErrorsImpl<PasswordResetFormData>;
    validateEmail: (value: string) => boolean | string;
    initiateReset: () => void;
    register: UseFormRegister<PasswordResetFormData>;
}

interface PasswordResetFormData {
    email: string;
}

export const InitiatedPasswordResetAction = "InitiatedPasswordReset";
function createInitiatedPasswordResetAction(
    email: string
): PayloadAction<string> {
    return {
        type: InitiatedPasswordResetAction,
        payload: email,
    };
}

export default function usePasswordReset(): PasswordResetData {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<PasswordResetFormData>();
    const dispatch = useAppDispatch();
    const email = watch("email");

    const initiateReset = handleSubmit(() => {
        dispatch(createInitiatedPasswordResetAction(email));
    });

    const passwordResetState = getQueryState(
        appServiceApi.endpoints.initiatePasswordReset.useQueryState(email)
    );

    const validateEmail = (value: string): boolean | string => {
        return isValidEmail(value) || "Invalid email address";
    };
    const emailIsValid = isValidEmail(email);

    return {
        email,
        emailIsValid,
        passwordResetState,
        errors,
        validateEmail,
        initiateReset,
        register,
    };
}
