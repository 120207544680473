import React from "react";
import classnames from "classnames";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./TimeEntry.module.css";
import DatePicker from "react-datepicker";
import { Clock } from "icons/icons";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends ComponentProps {}

function getTime(date: Date): string {
    const minutes = `${date.getMinutes()}`.padStart(2, "0");
    return `${date.getHours()}:${minutes}`;
}

export default function TimeEntry(props: Props) {
    let error;
    if (props.error || (props.problem && props.problemError) || (props.invalid && props.invalidError)) {
        let errorMessage;
        if (props.problem && props.problemError) {
            errorMessage = props.problemError;
            error = <p className={formStyles.problem}>{errorMessage}</p>;
        } else if (props.invalid && props.invalidError) {
            errorMessage = props.invalidError;
            error = <p className={formStyles.invalid}>{errorMessage}</p>;     
        } else {
            errorMessage = props.error?.message;
            error = <p className={formStyles.error}>{errorMessage}</p>;
        }
        
    }

    const onChange = (date: Date) => {
        props.onChange(props.model, getTime(date));
    };

    //let date: Date | undefined = void 0;
    let date = new Date();
    if (props.value && !(props.value instanceof Date)) {
        const timeParts = props.value.split(":");
        date = new Date();
        date.setHours(Number(timeParts[0]));
        date.setMinutes(Number(timeParts[1]));
    } else {
        onChange(date);
    }

    const CustomInput = (
        props: React.HTMLProps<HTMLInputElement>,
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <div className={styles.inputContainer}>
                <input
                    {...props}
                    className={styles.input}
                    inputMode="none"
                    onFocus={blur}
                />
                <Clock className={styles.clock} />
            </div>
        );
    };

    const blur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.blur();
    };

    let classes = [styles.TimeEntry];
    if (props.problem) {
        classes.push(styles.problem);
    }

    return (
        <div className={classnames(...classes)}>
            <p
                dangerouslySetInnerHTML={{ __html: props.label || "" }}
                className={formStyles.label}
            ></p>
            <DatePicker
                onChange={onChange}
                selected={date}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeCaption="Time"
                dateFormat="h:mm aa"
                customInput={React.createElement(React.forwardRef(CustomInput))}
                popperModifiers={[
                    {
                        name: "arrow",
                        options: { padding: 18 },
                    },
                ]}
            />

            {error}
        </div>
    );
}
