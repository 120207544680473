import { useCallback, useState } from "react";
import { useAppDispatch } from "app/hooks";
import { setShowInviteUser } from "app/appSlice";
import { isValidEmail } from "app/util";

export const InvitedUserActionType = "invitedUser";
function createInvitedUserAction(email: string) {
    return {
        type: InvitedUserActionType,
        payload: email,
    };
}

interface InviteUserData {
    email: string;
    emailIsValid: boolean;
    onUpdateEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onInvite: () => void;
    onCancel: () => void;
}

export default function useInviteUser(): InviteUserData {
    const [email, setEmail] = useState("");
    const dispatch = useAppDispatch();

    const onUpdateEmail = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
        },
        [setEmail]
    );

    const onInvite = useCallback(() => {
        dispatch(createInvitedUserAction(email));
        dispatch(setShowInviteUser(false));
    }, [email, dispatch]);

    const onCancel = useCallback(() => {
        dispatch(setShowInviteUser(false));
    }, [dispatch]);

    const emailIsValid = isValidEmail(email);

    return {
        email,
        emailIsValid,
        onUpdateEmail,
        onInvite,
        onCancel,
    };
}
