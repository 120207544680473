import {
    useForm,
    UseFormRegister,
    UseFormHandleSubmit,
    FieldErrorsImpl,
} from "react-hook-form";
import { useCallback, useState } from "react";
import { QueryState, getQueryState } from "services/util";
import { appServiceApi } from "services/appService";
import type { PasswordReset } from "services/appService";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectServerErrors, ServerError } from "app/appSlice";
import {
    selectResetPasswordCredential,
    selectBearerToken,
    selectIsLoggedIn,
    selectProfile,
} from "features/loginToken/loginTokenSlice";
import type { ResetPasswordCredential } from "features/loginToken/loginTokenSlice";
import { selectUrlsParams } from "app/appSlice";
import type { Profile } from "features/loginToken/types";

export type FormData = PasswordReset;

interface SetPasswordScreenData {
    setPasswordState: QueryState | undefined;
    errors: FieldErrorsImpl<FormData>;
    serverError?: ServerError;
    passwordErrorMessage?: string;
    resetPasswordCredential?: ResetPasswordCredential;
    generatedPassword?: string;
    isLoggedIn: boolean;
    showCurrent: boolean;
    showNew: boolean;
    showConfirm: boolean;
    profile?: Profile;
    handleSubmit: UseFormHandleSubmit<FormData>;
    register: UseFormRegister<FormData>;
    setPassword: (data: FormData) => void;
    validateConfirm: (value: string) => boolean | string;
    toggleCurrent: () => void;
    toggleNew: () => void;
    toggleConfirm: () => void;
}

export default function useSetPasswordScreen(): SetPasswordScreenData {
    const [showCurrent, setShowCurrent] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>();
    const current = watch("current");
    const newPassword = watch("new");
    const confirm = watch("confirm");
    const dispatch = useAppDispatch();
    const bearerToken = useAppSelector(selectBearerToken);
    const resetPasswordCredential = useAppSelector(
        selectResetPasswordCredential
    );
    const urlParams = useAppSelector(selectUrlsParams);
    const generatedPassword = urlParams["password"];
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const serverErrors = useAppSelector(selectServerErrors);
    const profile = useAppSelector(selectProfile);
    const passwordError = (serverErrors && serverErrors["password"]) || void 0;
    const passwordErrorMessage =
        passwordError?.data?.description || passwordError?.data?.message;
    let formData: FormData = {
        current,
        new: newPassword,
        confirm,
        uuid: profile?.id,
    };
    if (resetPasswordCredential) {
        formData = {
            ...formData,
            reset: resetPasswordCredential.token,
            uuid: resetPasswordCredential.uuid,
        };
        delete formData["current"];
    }
    if (bearerToken && generatedPassword) {
        formData = {
            ...formData,
            current: generatedPassword,
        };
    }

    const validateConfirm = (value: string): boolean | string => {
        return (
            value === newPassword ||
            "New password and confirmation do not match"
        );
    };

    const setPasswordState = getQueryState(
        appServiceApi.endpoints.setPassword.useQueryState(formData)
    );

    const setPassword = () => {
        dispatch(appServiceApi.endpoints.setPassword.initiate(formData));
    };

    const toggleCurrent = useCallback(() => {
        setShowCurrent(!showCurrent);
    }, [showCurrent, setShowCurrent]);

    const toggleNew = useCallback(() => {
        setShowNew(!showNew);
    }, [showNew, setShowNew]);

    const toggleConfirm = useCallback(() => {
        setShowConfirm(!showConfirm);
    }, [showConfirm, setShowConfirm]);

    return {
        setPasswordState,
        errors,
        passwordErrorMessage,
        resetPasswordCredential,
        generatedPassword,
        isLoggedIn,
        showCurrent,
        showNew,
        showConfirm,
        profile,
        handleSubmit,
        register,
        setPassword,
        validateConfirm,
        toggleCurrent,
        toggleNew,
        toggleConfirm,
    };
}
