import React, { useCallback } from "react";
import classnames from "classnames";
import styles from "./GroupChecklist.module.css";
import { TaskUrgencies, type TaskData, type TaskUrgency } from "./types";
import checkboxStyles from "./form/Checkbox.module.css";
import TaskInfo from "./TaskInfo";

interface Props {
    task: TaskData;
    checked: boolean;
    urgency: TaskUrgency;
    onCheck: (taskId: number, checked: boolean) => void;
}

export default function GroupChecklistItem(props: Props) {
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const isOn = e.currentTarget.checked;
            props.onCheck(props.task.id, isOn);
        },
        [props]
    );

    const onClick = useCallback(() => {
        props.onCheck(props.task.id, !props.checked);
    }, [props]);

    let classes = [styles.item];
    let status;
    switch (props.urgency) {
        case TaskUrgencies.URGENT:
            classes.push(styles.overdue);
            status = "overdue";
            break;
        case TaskUrgencies.DUE:
            classes.push(styles.due);
            status = "due";
            break;
    }
    if (props.checked) {
        classes.push(styles.checked);
    }

    return (
        <div className={classnames(...classes)} onClick={onClick}>
            <input
                className={checkboxStyles.checkbox}
                type="checkbox"
                checked={props.checked}
                onChange={onChange}
            />
            <div className={styles.itemData}>
                <div>
                    <h3>
                        {props.task.name}{" "}
                        {status && (
                            <TaskInfo task={props.task} status={status} />
                        )}
                    </h3>
                </div>
                <p className={styles.itemMethod}>{props.task.method}</p>
            </div>
        </div>
    );
}
