import { useCallback } from "react";
import { useAppSelector } from "app/hooks";
import { appDispatch, appSelect, appSelectArg } from "app/util";
import { RootState, getStore } from "app/store";
import {
    selectActiveVenueId,
    selectActiveUser,
    selectHasBeenIdle,
    setShowUserSelect,
    setActiveTasksScreen,
    addDiaryFilesToQueue,
    setPendingDiaryEntries,
    setPendingDiaryFiles,
} from "app/appSlice";
import { selectTask, selectTaskDiaryEntries } from "features/tasks/tasksSlice";
import { selectHasTaskSignoff } from "features/venue/venueSlice";
import { DiaryEntry, DiaryFile, TaskData } from "./types";
import { FormData, FormFile } from "./form/types";
import { TasksScreen, ActiveFormData } from "app/types";
import type { dataURL, base64 } from "types";
import { PayloadAction } from "@reduxjs/toolkit";
import {
    addDiaryEntries,
    addTaskDiaryEntries,
} from "features/tasks/tasksSlice";
import { generateUniqueId } from "features/environment/util";
import Scheduler from "./Scheduler";
import { CategoryView } from "features/environment/types";
import { getDataSources } from "./form/TaskForm";
import validateForm from "./form/validateForm";
import getTaskFormConfig from "./form/getTaskFormConfig";
import { getBatchNumber } from "./functions";
import { selectActiveUserId } from "app/util";

interface TaskScreenData {
    task?: TaskData;
    activeUserId?: number;
    hasTaskSignoff?: boolean;
    hasBeenIdle: boolean;
    provingCount?: number;
    batchNumber?: number;
    onChange: (data: Record<string, any>) => void;
    onResetShowErrors: () => void;
    onError: () => void;
    onSubmit: (data: Record<string, any>) => void;
    onClose: () => void;
    onAddStoredItem: (data: Record<string, any>, resetKeys?: string[]) => void;
    onRemoveStoredItem: (index: number) => void;
    formData?: FormData;
    categoryView?: CategoryView;
}

function parseDataUrl(dataUrl: dataURL): {
    mimeType: string;
    base64Data: base64;
} {
    const url = new URL(dataUrl);
    const parts = url.pathname.split(";base64,");
    const mimeType = parts[0];
    const base64Data = parts[1];

    return {
        mimeType,
        base64Data,
    };
}

function formatData(data: Record<string, any>) {
    const keys = Object.keys(data);
    let formattedData: Record<string, any> = {};
    for (let key of keys) {
        const parts = key.split(".");
        let value = data[key];
        // We have an embedded object in the data
        // e.g. {thermometer.topLeft: 10} becomes
        //      {thermometer: {topLeft: 10}}
        if (parts.length > 1) {
            let model = parts[0];
            let property = parts[1];
            if (!formattedData[model]) {
                formattedData[model] = {};
            }
            formattedData[model][property] = value;
        } else {
            formattedData[key] = value;
        }
    }

    return formattedData;
}

export default function useTaskScreen(
    screenSelector: (state: RootState) => TasksScreen,
    activeFormDataSelector: (state: RootState) => ActiveFormData | undefined,
    activeFormDataSetter: (
        data: ActiveFormData | undefined
    ) => PayloadAction<ActiveFormData | undefined>,
    activeScreenSetter: (screen: TasksScreen) => PayloadAction<TasksScreen>
): TaskScreenData {
    const activeTasksScreen = useAppSelector(screenSelector);
    let activeFormData = useAppSelector(activeFormDataSelector);
    const venueId = useAppSelector(selectActiveVenueId);
    if (!venueId) throw new Error("Missing venue id in useTaskScreen");

    const taskId = activeTasksScreen.taskId;
    const hasTaskSignoff = useAppSelector((state) =>
        selectHasTaskSignoff(state, venueId)
    );
    const activeUserId = useAppSelector((state) =>
        selectActiveUserId(state, venueId)
    );
    const hasBeenIdle = appSelect(selectHasBeenIdle);
    let task: TaskData | undefined;
    let categoryView: CategoryView | undefined;
    let provingCount;
    let batchNumber;
    let onChange = (data: Record<string, any>) => {};
    let onResetShowErrors = () => {};
    let onSubmit = (data: Record<string, any>) => {};
    let onError = () => {};
    let onAddStoredItem = (
        data: Record<string, any>,
        resetKeys?: string[]
    ) => {};
    let onRemoveStoredItem = (index: number) => {};
    const onClose = useCallback(() => {
        appDispatch(setActiveTasksScreen({}));
    }, []);
    let formData: FormData | undefined;
    if (venueId && taskId) {
        task = appSelect((state) => selectTask(state, venueId, taskId));
        if (task) {
            categoryView = getTaskFormConfig(task, getStore().getState());
            if (categoryView?.taskView) {
                formData = {
                    taskId: taskId,
                    title: task.name,
                    formView: categoryView.taskView,
                    data: {},
                    files: {},
                };

                if (activeFormData?.taskId === taskId) {
                    formData.data = activeFormData.data;
                    formData.showErrors = activeFormData.showErrors;
                    formData.storedItems = activeFormData.storedItems;
                    formData.files = activeFormData.files;
                } else {
                    activeFormData = void 0;
                    // Auto-populate sensor value if available
                    // TODO: set note field here?
                    if (task.source === "sensor" && task.reading) {
                        for (let component of categoryView.taskView
                            .components) {
                            if (component.useSensor && component.model) {
                                formData.data[component.model] =
                                    task.reading.average;
                            }
                        }
                    }
                }

                if (categoryView.taskView.showProving) {
                    // TODO: Do we need to specify task has proving in it's data?
                    //       Or just rely on configuration as we do now?
                    provingCount = task.proven_count;
                }

                if (categoryView.taskView.showBatchNumber) {
                    batchNumber = getTaskBatchNumber(task);
                }
            }

            onChange = (
                data: Record<string, any>,
                filesData?: Record<string, FormFile[]>
            ) => {
                const showErrors = activeFormData?.showErrors;
                filesData = filesData || {};
                appDispatch(
                    activeFormDataSetter({
                        taskId: taskId,
                        data,
                        showErrors,
                        storedItems: formData?.storedItems,
                        files: filesData,
                    })
                );
            };

            onResetShowErrors = () => {
                let formData = appSelect(activeFormDataSelector);
                if (formData) {
                    appDispatch(
                        activeFormDataSetter({
                            ...formData,
                            showErrors: false,
                        })
                    );
                }
            };

            // This is where DiaryEntry objects are created and added to the queue
            onSubmit = (data: Record<string, any>) => {
                if (!formData) return;

                let performed = Scheduler.getNowUtc();
                let lastDueCompleted;
                if (task && task.schedule) {
                    const scheduler = new Scheduler(task);
                    lastDueCompleted = scheduler.getLastDueUtc();
                } else {
                    lastDueCompleted = Scheduler.getNowUtc();
                }

                data = formatData(data);
                if (
                    task?.source === "sensor" &&
                    task.reading &&
                    task.reading.average
                ) {
                    data["reading"] = {
                        average: task.reading.average,
                    };
                }
                let diaryEntries: DiaryEntry[] = [];

                // Assume there are possible multiple items if
                // itemsKey is set
                let itemsKey = categoryView?.taskView.itemsKey;
                if (itemsKey) {
                    if (itemsKey in data && data[itemsKey]["type"]) {
                        // Assume data is valid and create diary
                        const diaryData = {
                            ...data,
                            ...data[itemsKey],
                        };
                        delete diaryData[itemsKey];
                        const diary: DiaryEntry = {
                            uuid: generateUniqueId(),
                            taskId: taskId,
                            information: JSON.stringify(diaryData),
                            venueId,
                            performed,
                            due: lastDueCompleted,
                        };
                        diaryEntries.push(diary);
                    }

                    if (
                        formData.storedItems &&
                        formData.storedItems?.length > 0
                    ) {
                        delete data[itemsKey];
                        for (let item of formData.storedItems) {
                            let diaryData = {
                                ...data,
                                ...item,
                            };
                            const diary: DiaryEntry = {
                                uuid: generateUniqueId(),
                                taskId: taskId,
                                information: JSON.stringify(diaryData),
                                venueId,
                                performed,
                                due: lastDueCompleted,
                            };

                            diaryEntries.push(diary);
                        }
                    }
                } else {
                    const diary: DiaryEntry = {
                        uuid: generateUniqueId(),
                        taskId: taskId,
                        information: JSON.stringify(data),
                        venueId,
                        performed,
                        due: lastDueCompleted,
                    };

                    diaryEntries.push(diary);
                }

                // NOTE: files do not currently work with multiple diary storedItems
                let diaryFiles: DiaryFile[] = [];
                if (activeFormData?.files && diaryEntries.length > 0) {
                    let diary = diaryEntries[0];
                    let files = activeFormData.files;
                    let filesModels = [];
                    for (let key in files) {
                        let componentFiles = files[key];
                        for (let file of componentFiles) {
                            let { mimeType, base64Data } = parseDataUrl(
                                file.data
                            );
                            let diaryFile: DiaryFile = {
                                diary_entry: diary.uuid,
                                filename: file.name,
                                file: base64Data,
                                mimeType,
                                venueId,
                                taskId,
                                performed,
                            };
                            diaryFiles.push(diaryFile);
                        }
                        filesModels.push(key);
                    }
                    let diaryInfo = JSON.parse(diary.information);
                    if (filesModels.length > 0)
                        diaryInfo["has_files"] = filesModels;

                    diaryEntries[0] = {
                        ...diary,
                        information: JSON.stringify(diaryInfo),
                    };
                }

                if (hasTaskSignoff && !activeUserId) {
                    appDispatch(setPendingDiaryEntries(diaryEntries));
                    if (diaryFiles.length > 0) {
                        appDispatch(setPendingDiaryFiles(diaryFiles));
                    }
                    appDispatch(setShowUserSelect(true));
                } else {
                    if (hasTaskSignoff) {
                        diaryEntries = diaryEntries.map(
                            (diaryEntry): DiaryEntry => {
                                diaryEntry.userId = activeUserId;
                                return diaryEntry;
                            }
                        );
                    }

                    if (diaryFiles.length > 0) {
                        appDispatch(addDiaryFilesToQueue(diaryFiles));
                    }
                    appDispatch(activeFormDataSetter(void 0));
                    appDispatch(addDiaryEntries(diaryEntries));
                    appDispatch(addTaskDiaryEntries(diaryEntries));
                    appDispatch(activeScreenSetter({}));
                }
            };

            onError = () => {
                let newActiveFormData: ActiveFormData;
                if (activeFormData) {
                    newActiveFormData = {
                        ...activeFormData,
                        showErrors: true,
                    };
                } else {
                    // If saving immediately there will be no activeFormData
                    newActiveFormData = {
                        taskId: taskId,
                        data: {},
                        showErrors: true,
                        files: {},
                    };
                }

                appDispatch(activeFormDataSetter(newActiveFormData));
            };

            // Stored items related functions

            onAddStoredItem = (
                data: Record<string, any>,
                resetKeys?: string[]
            ) => {
                if (activeFormData && task && formData) {
                    // First, validate the data
                    const dataSources = getDataSources(task);
                    const [
                        _enabledModels,
                        isValid,
                        _problems,
                        _required,
                        _ignore,
                    ] = validateForm(formData, dataSources, task);
                    if (!isValid) {
                        onError();
                        return;
                    }

                    let newActiveFormData = { ...activeFormData };
                    let storedItems: Array<object> = [];
                    if (activeFormData.storedItems) {
                        storedItems = [...activeFormData.storedItems];
                    }

                    storedItems.push(data);
                    if (resetKeys) {
                        let data = { ...newActiveFormData.data };
                        for (let key of resetKeys) {
                            if (key in data) {
                                delete data[key];
                            }
                        }
                        newActiveFormData.data = data;
                    }

                    appDispatch(
                        activeFormDataSetter({
                            ...newActiveFormData,
                            showErrors: false,
                            storedItems,
                        })
                    );
                }
            };

            onRemoveStoredItem = (index: number) => {
                if (activeFormData) {
                    if (
                        activeFormData.storedItems &&
                        activeFormData.storedItems.length > index
                    ) {
                        let storedItems = activeFormData.storedItems.filter(
                            (item, itemIndex) => itemIndex !== index
                        );
                        activeFormData = {
                            ...activeFormData,
                            storedItems,
                        };
                    }
                    appDispatch(activeFormDataSetter(activeFormData));
                }
            };
        }
    }

    return {
        task,
        activeUserId,
        hasTaskSignoff,
        hasBeenIdle,
        provingCount,
        batchNumber,
        onChange,
        onResetShowErrors,
        onSubmit,
        onError,
        onClose,
        onAddStoredItem,
        onRemoveStoredItem,
        formData,
        categoryView,
    };
}

function getTaskBatchNumber(task: TaskData): number {
    let batchNumber = 1;
    const taskDiaryEntries = appSelectArg(selectTaskDiaryEntries, task.id);
    if (taskDiaryEntries) batchNumber = getBatchNumber(taskDiaryEntries);

    return batchNumber;
}
