import React from "react";
import Form from "react-bootstrap/Form";
import Button from "features/tasks/form/Button";
import styles from "./InviteProfile.module.scss";
import formStyles from "app/form.module.scss";
import useInviteProfile from "./useInviteProfile";
import loaderStyles from "app/Loader.module.css";

type FormError = {
    type: string | undefined;
    message?: string;
};

const errorMessage = (
    error: FormError | undefined,
    message?: string
): JSX.Element => {
    let errorMessage = "";
    if (error && error.type === "required") {
        errorMessage = "This field is required";
    } else if (error && message) {
        errorMessage = message;
    } else if (error && error.message) {
        errorMessage = error.message;
    }

    return <p className={formStyles.error}>{errorMessage}</p>;
};

export default function InviteProfile() {
    const {
        isRegistering,
        errors,
        email,
        token,
        serverError,
        maxDob,
        register,
        onSubmit,
    } = useInviteProfile();

    let pageComponent;

    if (!token) {
        pageComponent = <h2>Invalid data</h2>;
    } else {
        pageComponent = (
            <>
                <h2>Welcome to Chomp</h2>
                <p>Please fill in below to create your Chomp profile:</p>

                <Form onSubmit={onSubmit}>
                    <Form.Group className={formStyles.formGroup}>
                        <Form.Label className={formStyles.required}>
                            First Name
                        </Form.Label>
                        <Form.Control
                            defaultValue={""}
                            {...register("firstName", { required: true })}
                        />
                        {errorMessage(errors.firstName)}
                    </Form.Group>
                    <Form.Group className={formStyles.formGroup}>
                        <Form.Label className={formStyles.required}>
                            Last Name
                        </Form.Label>
                        <Form.Control
                            defaultValue={""}
                            {...register("lastName", { required: true })}
                        />
                        {errorMessage(errors.lastName)}
                    </Form.Group>

                    <Form.Group className={formStyles.formGroup}>
                        <label className={formStyles.required}>Email</label>
                        <p>{email}</p>
                    </Form.Group>

                    <Form.Group className={formStyles.formGroup}>
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                            defaultValue={""}
                            type="tel"
                            minLength={9}
                            pattern="[0-9+ \(\)\-]+"
                            {...register("mobile")}
                        />
                        {errorMessage(errors.mobile)}
                    </Form.Group>

                    <Form.Group className={formStyles.formGroup}>
                        <Form.Label>Date of birth</Form.Label>
                        <Form.Control
                            defaultValue={""}
                            type="date"
                            max={maxDob}
                            {...register("dob")}
                        />
                        {errorMessage(errors.dob)}
                    </Form.Group>

                    {!isRegistering && (
                        <div className={styles.buttons}>
                            <Button type="submit">Complete Sign-up</Button>
                        </div>
                    )}
                    {isRegistering && (
                        <div className={styles.loginLoader}>
                            <div className={loaderStyles.Loader}>
                                <img
                                    src="/ChompSpinner.svg"
                                    alt="Logging in..."
                                />
                            </div>
                        </div>
                    )}
                    {serverError && (
                        <div className={formStyles.error}>
                            <p>
                                There was an error creating your account:{" "}
                                {JSON.parse(serverError.data).description}
                            </p>
                        </div>
                    )}
                </Form>
            </>
        );
    }

    return (
        <div className={styles.InviteProfile}>
            <div className={styles.logoContainer}>
                <img
                    src="/Chomp-Logo-simplified.png"
                    alt="Chomp"
                    className={styles.logo}
                />
            </div>
            {pageComponent}
        </div>
    );
}
