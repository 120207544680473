import { Metric } from "web-vitals";
import ReactGA from "react-ga4";
import sendDebugData from "features/debug/sendDebugData";

ReactGA.initialize("G-T2R6MXEPNH");

function reportHandler(metric: Metric) {
    ReactGA.event({
        category: "Web Vitals",
        action: metric.name,
        label: metric.id, // optional
        value: metric.value, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
    });
}

export default reportHandler;

export const reportException = (location: string, error: Error) => {
    sendDebugData(error.message, { stack: error.stack }, "error");
};
