import { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectUserVenues } from "features/loginToken/loginTokenSlice";
import { UserVenueData } from "features/loginToken/types";
import { setActiveVenueId, selectActiveVenueId } from "app/appSlice";

interface VenueSelectData {
    venues?: UserVenueData[];
    activeVenueId?: number;
    selectVenueId: (id?: number) => void;
}

export default function useVenueSelect(): VenueSelectData {
    const venues = useAppSelector(selectUserVenues);
    const activeVenueId = useAppSelector(selectActiveVenueId);
    const dispatch = useAppDispatch();
    const selectVenueId = useCallback(
        (id?: number) => {
            dispatch(setActiveVenueId(id));
        },
        [dispatch]
    );
    return {
        venues,
        activeVenueId,
        selectVenueId,
    };
}
