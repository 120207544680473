import React from "react";
import useActiveStaff from "./useActiveStaff";
import HeaderButton from "features/header/HeaderButton";
import styles from "features/header/Header.module.scss";

export default function ActiveStaff() {
    const { activeUser, hasBeenIdle, selectUser } = useActiveStaff();

    let content;
    if (activeUser && !hasBeenIdle) {
        content = (
            <>
                <span className={styles.currentUserLable}>
                    Current user:&nbsp;
                </span>
                {activeUser?.name}
            </>
        );
    } else {
        content = "No current user";
    }

    return <HeaderButton onClick={selectUser}>{content}</HeaderButton>;
}
