import React from "react";
import useSettingsScreen from "./useSettingsScreen";
import styles from "./SettingsScreen.module.css";
import Loader from "app/Loader";
import { Warning } from "icons/icons";
import classnames from "classnames";

import Alert from "react-bootstrap/Alert";

export default function SettingsScreen() {
    const {
        venueData,
        venueId,
        online,
        isLoggedIn,
        tasksQueryState,
        staffQueryState,
        suppliersQueryState,
        debugReportQueryState,
        reloadingPlan,
        reloadingTasks,
        reloadingStaff,
        reloadingSuppliers,
        submittingDebugReport,
        reloadPlan,
        reloadSuppliers,
        reloadTasks,
        reloadStaff,
        submitDebugReport,
        confirmFactoryReset,
    } = useSettingsScreen();

    let onlineStatus = online ? "Online" : "Offline";
    let alertClasses = ["m-0", "mb-5", "w-100", styles.alert];

    return (
        <div className={styles.SettingsScreen}>
            <h2 className={styles.header}>Settings</h2>
            <table className={styles.venueData}>
                <tbody>
                    {isLoggedIn && venueData && (
                        <>
                            <tr>
                                <td>Venue name:</td>
                                <td>{venueData.venueName}</td>
                            </tr>
                            <tr>
                                <td>Venue ID:</td>
                                <td>{venueId}</td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <td>App status:</td>
                        <td>{onlineStatus}</td>
                    </tr>
                </tbody>
            </table>

            {isLoggedIn && (
                <>
                    <Alert
                        key="warning"
                        variant="warning"
                        className={classnames(...alertClasses)}
                    >
                        <Warning /> Only use the following actions if asked to
                        by Chomp staff.
                    </Alert>

                    <button
                        className={styles.settingsButton}
                        onClick={reloadPlan}
                    >
                        Reload Plan
                    </button>

                    {/*
                    <button 
                        className={styles.settingsButton}
                        onClick={submitDebugReport}
                    >
                        Submit Debug Report
                    </button>
                    */}

                    <button
                        className={styles.settingsButton}
                        onClick={confirmFactoryReset}
                    >
                        Factory Reset
                    </button>

                    {reloadingPlan && <Loader>Reloading Plan...</Loader>}

                    {reloadingTasks &&
                        tasksQueryState &&
                        (tasksQueryState.isLoading ||
                            tasksQueryState.isFetching) && (
                            <Loader>Reloading Tasks...</Loader>
                        )}

                    {reloadingStaff &&
                        staffQueryState &&
                        (staffQueryState.isLoading ||
                            staffQueryState.isFetching) && (
                            <Loader>Reloading Staff...</Loader>
                        )}

                    {reloadingSuppliers &&
                        suppliersQueryState &&
                        (suppliersQueryState.isLoading ||
                            suppliersQueryState.isFetching) && (
                            <Loader>Reloading Suppliers...</Loader>
                        )}
                    
                    {submittingDebugReport &&
                        debugReportQueryState &&
                        (debugReportQueryState.isLoading ||
                            debugReportQueryState.isFetching) && (
                            <Loader>Submitting Debug Report...</Loader>
                        )}
                </>
            )}
        </div>
    );
}
