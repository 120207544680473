import React from "react";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import classnames from "classnames";

interface Props extends ComponentProps {}

export default function Subheading(props: Props) {
    const classes = [formStyles.subheading];
    if (props.problem) {
        classes.push(formStyles.problem);
    }
    if (props.invalid) {
        classes.push(formStyles.invalid);
    }
    return <h3 className={classnames(...classes)}>{props.label}</h3>;
}
