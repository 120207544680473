import { getStore } from "app/store";
import type { AppStartListening } from "app/listeners";
import { appSlice } from "app/appSlice";
import { selectNetworkQueue } from "app/appSlice";
import NetworkQueue from "./NetworkQueue";
import {
    CompletedOperationType,
    FailedOperationType,
    NetworkOperationFailedInfo,
} from "features/networkQueue/NetworkQueue";
import { NetworkOperation } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import ProfileOperationHandler from "features/profile/ProfileOperationHandler";
import InviteOperationHandler from "features/invite/InviteOperationHandler";
import { selectOnline } from "features/environment/envSlice";
import { selectIsLoggedIn } from "features/loginToken/loginTokenSlice";

const networkQueue = new NetworkQueue();
networkQueue.registerHandler(new ProfileOperationHandler());
networkQueue.registerHandler(new InviteOperationHandler());

const ONE_MINUTE = 60000;

function setupNetworkOperationQueue() {
    setInterval(() => {
        const state = getStore().getState();
        const loggedIn = selectIsLoggedIn(state);
        const isOnline = selectOnline(state);
        if (isOnline && loggedIn) processNetworkOperationQueue();
    }, ONE_MINUTE);
}

function processNetworkOperationQueue() {
    const store = getStore();
    const state = store.getState();
    const queuedOperations = selectNetworkQueue(state);
    networkQueue.processQueue(queuedOperations);
}

export const startNetworkQueueListening = (
    startListening: AppStartListening
) => {
    setupNetworkOperationQueue();

    startListening({
        actionCreator: appSlice.actions.queueNetworkOperation,
        effect: (action, listenerApi) => {
            const state = listenerApi.getState();
            const queuedOperations = selectNetworkQueue(state);
            networkQueue.processQueue(queuedOperations);
        },
    });

    startListening({
        actionCreator: appSlice.actions.setNetworkOperation,
        effect: (action, listenerApi) => {
            const state = listenerApi.getState();
            const queuedOperations = selectNetworkQueue(state);
            networkQueue.processQueue(queuedOperations);
        },
    });

    startListening({
        type: CompletedOperationType,
        effect: (action, listenerApi) => {
            let payloadAction = action as PayloadAction<NetworkOperation>;
            networkQueue.completedOperation(payloadAction.payload);
        },
    });

    startListening({
        type: FailedOperationType,
        effect: (action, listenerApi) => {
            let payloadAction =
                action as PayloadAction<NetworkOperationFailedInfo>;
            networkQueue.failedOperation(payloadAction.payload);
        },
    });
};

const listeners = [startNetworkQueueListening];
export default listeners;
