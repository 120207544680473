import React, { useCallback, useEffect, useState } from "react";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./DropdownList.module.css";
import type { DataSourceItem } from "./types";
import classnames from "classnames";

export default function DropdownList(props: ComponentProps) {
    // setup error message if required
    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }

    // setup problem message if required
    let problem;
    if (props.problem && props.problemError) {
        problem = <p className={formStyles.problem}>{props.problemError}</p>;
    }

    // setup invalid message if required
    let invalid;
    if (props.invalid && props.invalidError) {
        invalid = <p className={formStyles.invalid}>{props.invalidError}</p>;
    }

    // set up onchange and custom value
    const [selectedOption, setSelectedOption] = useState("");
    const [customValue, setCustomValue] = useState("");
    const onChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
            props.onChange(props.model, e.currentTarget.value);
            if (e.currentTarget.tagName.toUpperCase() === "TEXTAREA") {
                setCustomValue(e.currentTarget.value);
            } else {
                setSelectedOption(e.currentTarget.value);
            }
            if (
                props.dataSourceIdModel &&
                "selectedOptions" in e.currentTarget
            ) {
                if (e.currentTarget.selectedOptions[0].dataset.optionId) {
                    props.onChange(
                        props.dataSourceIdModel,
                        e.currentTarget.selectedOptions[0].dataset.optionId
                    );
                }
            }
        },
        [props]
    );

    // set up options for dropdown
    let optionsData: DataSourceItem[] = [];
    if (props.dataSource) {
        let dataSource = props.dataSources[props.dataSource];
        if (dataSource && Array.isArray(dataSource)) {
            if (props.dataSourceValue && props.dataSourceValue === "label") {
                dataSource = dataSource.map((dataSource) => {
                    return {
                        value: dataSource.label,
                        label: dataSource.label,
                        id:
                            typeof dataSource.value !== "boolean"
                                ? dataSource.value
                                : dataSource.label,
                    };
                });
            }
            optionsData = dataSource;
        }
    } else if (props.options) {
        optionsData = props.options;
    }

    optionsData = [
        {
            value: "",
            label: "---------",
        },
        ...optionsData,
    ];

    let textEntry;
    const options = optionsData.map((optionData, index) => {
        const key = `${props.model}_${optionData.value}_${index}`;
        const value =
            typeof optionData.value === "boolean"
                ? String(optionData.value)
                : optionData.value;
        let selected = selectedOption === optionData.value;
        if (optionData.custom === true) {
            if (selected) {
                textEntry = (
                    <textarea
                        className={styles.customValue}
                        placeholder={String(optionData.value)}
                        value={customValue}
                        onChange={onChangeCallback}
                    ></textarea>
                );
            }
        }
        return (
            <option key={key} value={value} data-option-id={optionData.id}>
                {optionData.label}
            </option>
        );
    });

    // setup default value
    let defaultValue: any;
    if (
        (props.value === null || props.value === void 0) &&
        props.default !== null &&
        props.default !== void 0
    ) {
        defaultValue = props.default;
    }
    useEffect(() => {
        if (defaultValue) {
            props.onChange(props.model, defaultValue);
        }
    }, [props, defaultValue]);

    const classes = [styles.dropdown];
    if (props.problem) {
        classes.push(styles.problem);
    }

    const dropdown = (
        <div className={styles.DropdownList}>
            <p className={formStyles.label}>{props.label}</p>
            {problem} {invalid}
            <select
                className={classnames(...classes)}
                name={props.model}
                value={props.value || defaultValue}
                onChange={onChangeCallback}
            >
                {options}
            </select>
        </div>
    );

    return (
        <div>
            {dropdown}
            {textEntry}
            {error}
        </div>
    );
}
