import React from "react";
import classnames from "classnames";
import styles from "./TaskGroup.module.css";
import type { TaskData, TaskStatus } from "./types";
import { 
    groupName, 
    TaskObjectsFunction, 
    getGroupUrgency, 
    getLastPerformedMessage 
} from "./functions";
import TaskGroupPopover from "./TaskGroupPopover";
import { Flipped } from "react-flip-toolkit";
import taskIcons from "./taskIcons";

interface Props {
    status: TaskStatus;
    group: TaskData[];
    showPopover?: boolean;
    getTaskObjects: TaskObjectsFunction;
    handleClick: (
        group: TaskData[],
        status: TaskStatus,
        getTaskObjects: TaskObjectsFunction
    ) => void;
}

const onExit = (el: HTMLElement, i: number, removeElement: () => void) => {
    setTimeout(() => {
        el.classList.add(styles.fadeOut);
        setTimeout(removeElement, 300);
    }, 0);
};

export default function TaskGroup(props: Props) {
    let classNames = [styles.taskGroup];
    let isEvent = false;
    switch (props.status) {
        case "urgent":
            classNames.push(styles.urgent);
            break;
        case "frequent":
        case "event":
            classNames.push(styles.event);
            isEvent = true;
            break;
        default:
            classNames.push(styles.due);
            break;
    }

    const category = props.getTaskObjects(props.group[0]);
    let categoryView;
    if (category?.configuration && !Array.isArray(category.configuration)) {
        categoryView = category.configuration;
    }
    let Icon: React.FunctionComponent | undefined;
    if (
        categoryView &&
        categoryView.taskIcon &&
        categoryView.taskIcon in taskIcons
    ) {
        Icon = taskIcons[categoryView.taskIcon];
    }

    const name = groupName(props.group, props.getTaskObjects)

    const onClick = (e: any) => {
        props.handleClick(props.group, props.status, props.getTaskObjects);
    };

    const id = `${props.status}_${name}`;

    let overdueCount = 0;
    let dueCount = 0;
    let now = new Date().getTime() / 1000;
    for (let task of props.group) {
        if (task.overdueTime) {
            if (task.overdueTime < now) {
                overdueCount++;
            } else {
                dueCount++;
            }
        }
    }

    let headingMessages = [];
    if (overdueCount > 0) {
        headingMessages.push(`${overdueCount} overdue`);
    }
    if (dueCount > 0) {
        headingMessages.push(`${dueCount} due`);
    }
    let headingMessage = headingMessages.join(", ");

    if (isEvent) {
        const lastPerformed = props.group.reduce((prev: number, current) => {
            return current.performed && current.performed > prev
                ? current.performed
                : prev;
        }, 0);
        if (lastPerformed) {
            headingMessage = `Last entry: ${getLastPerformedMessage(
                lastPerformed
            )}`;
        }
    }
    headingMessage = `${headingMessage}`;
    let taskGroup = (
        <div className={classnames(...classNames)} onClick={onClick}>
            <div className={styles.header}>
                {Icon && (
                    <div className={styles.icon}>
                        <Icon />
                    </div>
                )}
                <p className={styles.groupCount}>{headingMessage}</p>
            </div>

            <h3 className={styles.groupName}>{name}</h3>
        </div>
    );

    if (props.showPopover) {
        taskGroup = (
            <TaskGroupPopover
                group={props.group}
                getTaskObjects={props.getTaskObjects}
                categoryView={categoryView}
            >
                {taskGroup}
            </TaskGroupPopover>
        );
    }

    taskGroup = (
        <Flipped flipId={id} stagger onExit={onExit}>
            {taskGroup}
        </Flipped>
    );

    return taskGroup;
}
