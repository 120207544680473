import { useState } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectProfile } from "features/loginToken/loginTokenSlice";
import type { Profile } from "features/loginToken/types";
import { showRoute } from "app/routeListeners";

interface ProfileScreenData {
    isEditing: boolean;
    profile?: Profile;
    toggleEdit: () => void;
    showChangePassword: () => void;
}

export default function useProfileScreen(): ProfileScreenData {
    const profile = useAppSelector(selectProfile);
    let [isEditing, setIsEditing] = useState(false);
    const dispatch = useAppDispatch();

    let toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    let showChangePassword = () => {
        dispatch(showRoute("changePassword"));
    };

    return {
        isEditing,
        profile,
        toggleEdit,
        showChangePassword,
    };
}
